import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StatisticsType, StatisticsReducer } from "./types";
import { loadStatisticsAction } from "./thunk";
import { AxiosError } from "axios";

const initialState: StatisticsReducer = {
  isLoading: false,
  isLoaded: false,
  error: null,
  statistics: null,
};

export const statisticsSlice = createSlice({
  name: "StatsSlice",
  initialState,
  reducers: {
    setStatisticsData: (
      state: StatisticsReducer,
      action: PayloadAction<StatisticsType | null>
    ) => {
      state.statistics = action.payload;
    },
    setStatsLoadStatus: (
      state: StatisticsReducer,
      action: PayloadAction<boolean>
    ) => {
      state.isLoaded = action.payload;
    }
  },
  extraReducers: {
    [loadStatisticsAction.pending.type]: (state: StatisticsReducer) => {
      state.isLoading = true;
      state.error = null;
    },
    [loadStatisticsAction.fulfilled.type]: (
      state: StatisticsReducer,
      { payload }: PayloadAction<StatisticsType>
    ) => {
      state.statistics = payload;
      state.isLoading = false;
      state.isLoaded = true;
    },
    [loadStatisticsAction.rejected.type]: (
      state: StatisticsReducer,
      { payload }: PayloadAction<AxiosError>
    ) => {
      state.statistics = null;
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const {
  setStatisticsData,
  setStatsLoadStatus
} = statisticsSlice.actions;
export default statisticsSlice.reducer;
