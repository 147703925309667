/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  getAthleteFullName,
  getStatsEvents,
  getStatsVolunteerings,
  getAthleteStatistics,
  getFinishTimes,
  getEventDates,
  getEventNames,
  getPersonalBestData,
} from '../../store/statistics/selectors';
import {
  getAthleteId,
  getParkrunId,
  getRunCount,
  getPersonalBest,
  getVolunteeringSaturdays
} from '../../store/athlete/selectors';
import { useAppSelector } from '../../customHooks/useAppSelector';
import {
  REACT_APP_SITE_URL,
  ExternalLink,
  AppRoute,
  paceDataToShow
} from '../../const';
import styles from './index.module.scss';
import { nanoid } from 'nanoid';
import { Spin } from "antd";
import LineChart from '../../components/Charts/LineChart';
import DoughnutChart from '../../components/Charts/DoughnutChart';
import {
  getPace,
  getChartData,
  getMaxRoundElement,
  getMinRoundElement,
  getUniqueItems,
  getArrayOfUniqueItemsQuantities,
  getDataChunk
} from '../../utils';
import ClubRunIcon from '../../components/ClubRunIcon/ClubRunIcon';
import ClubVolunteeringIcon from '../../components/ClubVolunteeringIcon/ClubVolunteeringIcon';

function StatisticsScreen(): JSX.Element {
  const athleteID = useAppSelector(getAthleteId);
  const parkrunID = useAppSelector(getParkrunId);

  const athleteFullName = useAppSelector(getAthleteFullName);
  const statsEvents = useAppSelector(getStatsEvents);
  const volunteeringsEvents = useAppSelector(getStatsVolunteerings);
  const athleteStatistics = useAppSelector(getAthleteStatistics);
  const athleteResults = useAppSelector(getFinishTimes);

  const runCount = useAppSelector(getRunCount);
  const volunteeringCount = useAppSelector(getVolunteeringSaturdays);

  const personalBestData = useAppSelector(getPersonalBestData);
  const clubMembershipData = personalBestData && personalBestData.club_membership;
  const clubRun = (personalBestData && clubMembershipData) && clubMembershipData.run;
  const clubVolunteering = (personalBestData && clubMembershipData) && clubMembershipData.volunteer;
  
  const personalBest = useAppSelector(getPersonalBest);
  const personalBestTime = personalBest?.finish_time;
  const personalBestEvent = personalBest?.event_name;
  const personalBestDate = personalBest?.event_date;
  const personalBestEventUrl = personalBest?.event_url;

  const eventDates = useAppSelector(getEventDates);
  const eventNames = useAppSelector(getEventNames);
  const athletePaces = athleteResults?.map((result) => getPace(result));

  const volunteerRoles = volunteeringsEvents?.map((event) => event.volunteer_role_name);
  const uniqueVolunteerRoles = getUniqueItems(volunteerRoles);

  const results = getChartData(athletePaces, eventDates, eventNames);
  const volunteerings = getArrayOfUniqueItemsQuantities(volunteerRoles, uniqueVolunteerRoles);

  const additionalChartCaptionText = `(последние ${paceDataToShow} стартов)`;

  const chunkResults = (results && results.length > paceDataToShow) ? getDataChunk(results, paceDataToShow) : results;

  const [ resultsToShow, setResultsToShow ] = useState(chunkResults);
  const [ buttonText, setButtonText ] = useState('все старты');
  const [ isAllResultsToShow, setIsAllResultsToShow ] = useState(false);
  const [ additionalText, setAdditionalText ] = useState(additionalChartCaptionText);

  const buttonClickHandler = (evt: React.MouseEvent) => {
    evt.preventDefault();
    if (!isAllResultsToShow) {
      setResultsToShow(results);
      setIsAllResultsToShow(true);
      setAdditionalText('');
      setButtonText(`${paceDataToShow} последних`);
    } else {
      setResultsToShow(chunkResults);
      setIsAllResultsToShow(false);
      setButtonText('все старты');
      setAdditionalText(additionalChartCaptionText);
    }
  };

  return (
    <div className={styles.mainwrapper}>
      {!athleteStatistics && <div className={styles.spinner}>
                <Spin size="large" />
              </div>}
      {(!statsEvents && !volunteeringsEvents) && 
      <p className={styles.wtext}>{athleteFullName} у Вас пока нет статистики по участию в 5 вёрст.</p>}
      {(statsEvents || volunteeringsEvents) && 
      <div className={styles.maincontent}>
        <section className={styles.section}>
          <h1 className={styles.athletename}>{athleteFullName} - статистика участника.</h1>
          <h2 className={styles.athleteid}><a href={`${ExternalLink.UserStatsUrl}${athleteID}`} target="_blank" rel="noreferrer">A{athleteID}</a></h2>
          <div className={styles.blockicons}>
            <ClubRunIcon clubRun={clubRun} />
            <ClubVolunteeringIcon clubVolunteering={clubVolunteering} />
          </div>
          <div className={styles.summary}>
            <p className={styles.textsum}>Всего финишей: {runCount}</p>
            <p className={styles.textsum}>Всего волонтерств: {volunteeringCount}</p>
              {personalBest && <p className={styles.textsum}>Лучшее время: {personalBestTime}, 
              5 вёрст {personalBestEvent} <a href={`${ExternalLink.VerstSiteUrl}${personalBestEventUrl}/results/${personalBestDate}/`} target="_blank" rel="noreferrer">{personalBestDate}</a> 
            </p>}
          </div>
        </section>
        <section className={styles.statistics}>
          {statsEvents ?
          <div className={styles.resultwrapper}>
            <div className={styles.tableblock}>
              <h2 className={styles.tablecaption}>Результаты</h2>
              <table className={styles.results}>
                <thead>
                  <tr>
                    <th className={`${styles.theading} ${styles.date}`}>Дата</th>
                    <th className={`${styles.theading} ${styles.location}`}>Локация</th>
                    <th className={`${styles.theading} ${styles.time}`}>Время</th>
                    <th className={`${styles.theading} ${styles.pace}`}>Темп<br />(мин/км)</th>
                  </tr>
                </thead>
                <tbody>
                  {statsEvents.map((event) => {
                    const isTestResult = event.event_status.id === 1;
                    return (
                      <tr key={nanoid()}>
                        <td className={styles.tcell}>
                          <a
                            className={styles.tlink}
                            href={`${REACT_APP_SITE_URL}${event.event_url}/results/${event.event_date}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {event.event_date}
                          </a>
                          {isTestResult && ' (тест)'}
                        </td>
                        <td className={styles.tcell}>
                          <a
                            className={styles.tlink}
                            href={`${REACT_APP_SITE_URL}${event.event_url}/results/${event.event_date}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {event.event_name}
                          </a>
                        </td>
                        <td className={styles.tcell}>{event.finish_time}</td>
                        <td className={styles.tcell}>{getPace(event.finish_time)}</td>
                      </tr>
                    ) 
                  })}
                </tbody>
              </table>
              <span className={styles.chartlink}>Посмотреть <Link to={AppRoute.ChartPace}>график темпа</Link></span>
            </div>
            <div className={styles.chartblock}>
              <h2 className={styles.chartcaption}>График темпа {results && results.length > paceDataToShow && additionalText}</h2>
              <LineChart
                chartData={resultsToShow ? resultsToShow : chunkResults}
                maxPace={getMaxRoundElement(athletePaces)}
                minPace={getMinRoundElement(athletePaces)}
                xAxeTicks={eventDates}
              />
              {results && results.length > paceDataToShow && <button 
                className={styles.chatdatatoggle}
                onClick={buttonClickHandler}
              >Показать {buttonText}</button>}
            </div>
          </div> : <p className={styles.wtextres}>У Вас пока нет статистики по результатам</p>}
          {volunteeringsEvents ?
          <div className={styles.volunteeringwrapper}>
            <div className={styles.tableblock}>
              <h2 className={styles.tablecaption}>Волонтёрства</h2>
              <table className={styles.volunteerings}>
                <thead>
                  <tr>
                    <th className={`${styles.theading} ${styles.date}`}>Дата</th>
                    <th className={`${styles.theading} ${styles.location}`}>Локация</th>
                    <th className={`${styles.theading} ${styles.role}`}>Роль</th>
                  </tr>
                </thead>
                <tbody>
                  {volunteeringsEvents.map((volunteering) => {
                    const isTestVolunteering = volunteering.event_status.id === 1;
                    return (
                      <tr key={nanoid()}>
                        <td className={styles.tcell}>
                          <a
                            className={styles.tlink}
                            href={`${REACT_APP_SITE_URL}${volunteering.event_url}/results/${volunteering.event_date}/#volunteers`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {volunteering.event_date}
                          </a>
                          {isTestVolunteering && ' (тест)'}
                        </td>
                        <td className={styles.tcell}>
                          <a
                            className={styles.tlink}
                            href={`${REACT_APP_SITE_URL}${volunteering.event_url}/results/${volunteering.event_date}/#volunteers`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {volunteering.event_name}
                          </a>
                        </td>
                        <td className={styles.tcell}>{volunteering.volunteer_role_name}</td>
                      </tr>
                    ) 
                  })}
                </tbody>
              </table>
              <span className={styles.chartlink}>Посмотреть <Link to={AppRoute.ChartVolunteering}>график волонтёрств</Link></span>
            </div>
            <div className={styles.chartblock}>
              <h2 className={`${styles.chartcaption} ${styles.chartcaptionvolunt}`}>Статистика волонтёрств</h2>
              <DoughnutChart
                chartData={volunteerings}
                chartLabels={uniqueVolunteerRoles}
              />
            </div>
          </div> : <p className={styles.wtextvol}>У Вас пока нет статистики по волонтёрствам</p>} 
        </section>
        {parkrunID && <p className={styles.wtext}>Ваши результаты на сайте <a href={`${ExternalLink.ParkrunnerUrl}${parkrunID}/`} target="_blank" rel="noreferrer">паркран</a></p>}
      </div>}
    </div>
  );
}

export default StatisticsScreen;
