import { Link } from 'react-router-dom';
import {
  getAthleteQRcode,
  getAthleteFirstName,
  getAthleteLastName
} from '../../store/athlete/selectors';
import { 
  getAthleteId,
  getAthleteIceName,
  getAthleteIceContact
} from '../../store/athlete/selectors';
import { useAppSelector } from '../../customHooks/useAppSelector';
import { useAppDispatch } from '../../customHooks/useAppDispatch';
import { loadORDocAction } from '../../store/athlete/thunk';
import { AppRoute, ExternalLink } from '../../const';
import styles from './index.module.scss';

function PersonalScreen(): JSX.Element {
  const athleteID = useAppSelector(getAthleteId);
  const athleteFirstName = useAppSelector(getAthleteFirstName);
  const athleteLastName = useAppSelector(getAthleteLastName);
  const athleteQRcode = useAppSelector(getAthleteQRcode);
  const athleteIceName = useAppSelector(getAthleteIceName);
  const athleteIceContact = useAppSelector(getAthleteIceContact);

  const dispatch = useAppDispatch();

  const handleClickPrint = () => {
    dispatch(loadORDocAction());
  };

  return (
    <div className={styles.mainwrapper}>
      <section className={styles.section} id="section-to-print">
        <h1 className="visually-hidden">Ваш QR-код</h1>
        <p className={styles.username}>{athleteFirstName} {athleteLastName?.toUpperCase()}</p>
        <p className={styles.verstid}>5 вёрст ID: <a href={`${ExternalLink.UserStatsUrl}${athleteID}`} target="_blank" rel="noreferrer">A{athleteID}</a></p>
        <div className={styles.blockimage}>
          <img className={styles.codeimage} src={`data:image/PNG;base64,${athleteQRcode}`} alt="qr-code" />
        </div>
        <p className={styles.icetitle}>Контактное лицо для экстренной связи:</p>
        <p className={styles.icedata}>{athleteIceName} {athleteIceContact}</p>
        <Link to={AppRoute.QRcode} className={styles.link} onClick={handleClickPrint}>
          Печать QR-кода
        </Link>
        <a className={styles.buttonshop} href={`${ExternalLink.ShopCardUrl}`} target="_blank" rel="noreferrer">Заказать карточку</a>
      </section>
    </div>
    
  )
}

export default PersonalScreen;
