/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import styles from "./index.module.scss";
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { Select, notification, Spin } from "antd";
import {
  getAthleteFirstName,
  getAthleteLastName,
  getAthleteMiddleName,
  getAthleteIceName,
  getAthleteIceContact,
  getAthleteHomeEventId,
  getAthletePhone,
} from "../../store/athlete/selectors";
import { useAppSelector } from "../../customHooks/useAppSelector";
import { useAppDispatch } from "../../customHooks/useAppDispatch";
import { updateAthleteAction } from "../../store/athlete/thunk";
import {
  setAthleteLastName,
  setAthleteFirstName,
  setAthleteMiddleName,
  setAthleteIceName,
  setAthleteHomeEventId,
} from "../../store/athlete/slice";
import { getEvents, getIsLoading } from "../../store/athlete/selectors";
import { WarningMessage } from "../../const";
//import { validatePhoneLength } from "../../utils";
import { IMaskInput } from 'react-imask';

const { Option } = Select;

function PersonalScreen(): JSX.Element {
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(getIsLoading);
  const athleteLastName = useAppSelector(getAthleteLastName) as string;
  const athleteFirstName = useAppSelector(getAthleteFirstName) as string;
  const athleteMiddleName = useAppSelector(getAthleteMiddleName);
  const athleteIceName = useAppSelector(getAthleteIceName);
  const athletePhone = useAppSelector(getAthletePhone) as string;
  const athleteIceContact = useAppSelector(getAthleteIceContact) as string;
  const athleteHomeEventId = useAppSelector(getAthleteHomeEventId);
  const events = useAppSelector(getEvents);

  const lastNameRef = useRef<HTMLInputElement | null>(null);
  const firstNameRef = useRef<HTMLInputElement | null>(null);
  const middleNameRef = useRef<HTMLInputElement | null>(null);
  const iceNameRef = useRef<HTMLInputElement | null>(null);
  const userContactRef = useRef<HTMLInputElement | null>(null);
  const iceContactRef = useRef<HTMLInputElement | null>(null);
  const buttonSubmitRef = useRef<HTMLButtonElement | null>(null);
  const linkMailRef = useRef<HTMLAnchorElement | null>(null);

  const [athletePhoneNumber, setAthletePhoneNumber] = useState(athletePhone);
  const [iceContactPhone, setIceContactPhone] = useState(athleteIceContact);

  const getFocus = () => {
    const focusableElements = [
      lastNameRef.current,
      firstNameRef.current,
      middleNameRef.current,
      iceNameRef.current,
      iceContactRef.current,
      buttonSubmitRef.current,
      linkMailRef.current,
    ];
    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement =
      focusableElements[focusableElements.length - 1];

    document.addEventListener("keydown", (evt: KeyboardEvent) => {
      const isTabPressed = evt.key === "Tab";

      if (!isTabPressed) {
        return;
      }

      if (evt.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement?.focus();
          evt.preventDefault();
        }
      } else {
        if (document.activeElement === lastFocusableElement) {
          firstFocusableElement?.focus();
          evt.preventDefault();
        }
      }
    });
  };

  useEffect(() => lastNameRef.current?.focus(), []);

  useEffect(() => {
    getFocus();
  });

  const handleSubmit = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    if (!athleteLastName || !athleteFirstName) {
      notification.warn({message: WarningMessage.NotEmptyInput});
      return;
    }

    dispatch(
      updateAthleteAction({
        first_name: athleteFirstName,
        middle_name: athleteMiddleName,
        last_name: athleteLastName,
        ice_name: athleteIceName,
        ice_contact: iceContactPhone,
        home_event_id: athleteHomeEventId,
      })
    );
  };

  const nameRegex = /^[\.а-яА-Яa-zA-ZёЁ-]*$/;

  const handleInputLastName = (evt: ChangeEvent<HTMLInputElement>): void => {
    const currentLastName = evt.target.value;
    if (currentLastName.match(nameRegex)) {
      dispatch(setAthleteLastName(currentLastName));
    }
  };
  const handleInputFirstName = (evt: ChangeEvent<HTMLInputElement>): void => {
    const currentFirstName = evt.target.value;
    if (currentFirstName.match(nameRegex)) {
      dispatch(setAthleteFirstName(currentFirstName));
    }
  };
  const handleInputMiddleName = (evt: ChangeEvent<HTMLInputElement>): void => {
    const currentMiddleName = evt.target.value;
    if (currentMiddleName.match(nameRegex)) {
      dispatch(setAthleteMiddleName(currentMiddleName));
    }
  };
  const handleChangeIceName = (evt: ChangeEvent<HTMLInputElement>): void => {
    const currentIceName = evt.target.value;
    if (currentIceName.match(nameRegex)) {
      dispatch(setAthleteIceName(currentIceName));
    }
  };


  const handleEventChange = (id: number) => {
    dispatch(setAthleteHomeEventId(id));
  };

  /* const handleAthletePhoneInput = (evt: ChangeEvent<HTMLInputElement>): void => {
    if (evt.target === userContactRef.current) {
      userContactRef.current.setCustomValidity(validatePhoneLength(userContactRef.current.value));
      userContactRef.current.reportValidity();
    }
  };

  const handleIceContactPhoneInput = (evt: ChangeEvent<HTMLInputElement>): void => {
    if (evt.target === iceContactRef.current) {
      iceContactRef.current.setCustomValidity(validatePhoneLength(iceContactRef.current.value));
      iceContactRef.current.reportValidity();
    }
  }; */

  return (
    <div className={styles.mainwrapper}>
      <section className={styles.page}>
        <h2 className={styles.heading}>Введите новые данные:</h2>
        <form className="personal-data" onSubmit={handleSubmit}>
          {/* ФИО */}
          <div className={styles.block}>
            <div className={styles.wrapper}>
              <label className={styles.label} htmlFor="lastname">
                Фамилия:<span className={styles.required}>*</span>
              </label>
              <input
                className={styles.input}
                value={athleteLastName}
                type="text"
                name="user-lastname"
                id="lastname"
                ref={lastNameRef}
                onChange={handleInputLastName}
              />
            </div>
            <div className={styles.wrapper}>
              <label className={styles.label} htmlFor="firstname">
                Имя:<span className={styles.required}>*</span>
              </label>
              <input
                className={styles.input}
                value={athleteFirstName}
                type="text"
                name="user-firstname"
                id="firstname"
                ref={firstNameRef}
                onChange={handleInputFirstName}
              />
            </div>
            <div className={styles.wrapper}>
              <label className={styles.label} htmlFor="middlename">
                Отчество:
              </label>
              <input
                className={styles.input}
                value={athleteMiddleName}
                type="text"
                name="user-middlename"
                id="middlename"
                ref={middleNameRef}
                onChange={handleInputMiddleName}
              />
            </div>
          </div>

          {/* Домашний парк */}
          <div className={styles.block}>
            <div className={styles.wrapper}>
              <label className={styles.label} htmlFor="middlename">
                Домашние 5 вёрст:
              </label>
              <Select value={athleteHomeEventId} onChange={handleEventChange}>
                {events.map((event) => (
                  <Option key={event.id} value={event.id}>
                    {event.name} ({event.city_name})
                  </Option>
                ))}
              </Select>
            </div>
            {/* Мобильный телефон */}
            <div className={styles.wrapper}>
              <label className={styles.label} htmlFor="usercontact">
                Ваш мобильный телефон:
              </label>
              <IMaskInput
                className={styles.input}
                mask="+7(000)000-00-00"
                value={athletePhoneNumber}
                type="text"
                name="user-contact"
                id="usercontact"
                ref={userContactRef}
                onAccept={setAthletePhoneNumber}
                placeholder="+7(000)000-00-00"
                readOnly={true}
              />
            </div>
          </div>

          {/* Контактное лицо */}
          <div className={styles.block}>
            <div className={styles.wrapper}>
              <label className={styles.label} htmlFor="icename">
                Контактное лицо для экстренной связи:
              </label>
              <input
                className={styles.input}
                value={athleteIceName}
                type="text"
                name="user-icename"
                id="icename"
                ref={iceNameRef}
                onChange={handleChangeIceName}
              />
            </div>
            <div className={styles.wrapper}>
              <label className={styles.label} htmlFor="icecontact">
                Телефон контактного лица:
              </label>
              <IMaskInput
                className={styles.input}
                mask="+7(000)000-00-00"
                value={iceContactPhone}
                type="text"
                name="user-icecontact"
                id="icecontact"
                ref={iceContactRef}
                onAccept={setIceContactPhone}
                placeholder="+7(000)000-00-00"
              />
            </div>
          </div>
          {isLoading ? (
            <div className={styles.spinner}>
              <Spin size="large" />
            </div>
          ) : null}
          <button className={styles.button} ref={buttonSubmitRef}>Изменить</button>
        </form>
        <div className={styles.message}>
          Для изменения parkrun ID, e-mail, номера телефона или других данных обратитесь,
          пожалуйста, на <a className={styles.link} href="mailto:support@5verst.ru" ref={linkMailRef}>support@5verst.ru</a>
        </div>
      </section>
    </div>
  );
}

export default PersonalScreen;
