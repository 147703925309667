import { Link, useNavigate } from "react-router-dom";
import { AppRoute } from "../../../const";
import { useAppDispatch } from "../../../customHooks/useAppDispatch";
import { dropToken } from "../../../services/token";
import { userLogout } from "../../../store/user/slice";
import { 
  setAthleteData,
  setEventsData,
  setLoadStatus
} from "../../../store/athlete/slice";
import { setLoadRzdBonusStatus, setRzdBonusInitialState } from "../../../store/rzdbonus/slice"; 
import { setJoinAgreeStatus, setJoinSMStatus } from "../../../store/sportmasterbonus/slice";
import { setStatisticsData, setStatsLoadStatus } from "../../../store/statistics/slice";
import styles from "./index.module.scss";

function LogoutLink(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const resetDataState = () => {
    dispatch(setAthleteData(null));
    dispatch(setStatisticsData(null));
    dispatch(setStatsLoadStatus(false));
    dispatch(setEventsData([]));
    dispatch(setLoadStatus(false));
    dispatch(setRzdBonusInitialState());
    dispatch(setLoadRzdBonusStatus(false));
    dispatch(setJoinAgreeStatus(false));
    dispatch(setJoinSMStatus(false));
    localStorage.clear();
  }

  return (
    <Link
      to={AppRoute.Login}
      className={styles.link}
      onClick={(evt) => {
        evt.preventDefault();
        dropToken();
        dispatch(userLogout());
        resetDataState();
        navigate(AppRoute.Login);
      }}
    >
      Выйти
    </Link>
  );
}

export default LogoutLink;
