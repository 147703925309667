import styles from './index.module.scss';
import { ClubIconSize, ClubRuns } from '../../const';

type ClubRunIconProps = {
  clubRun: string | undefined;
}

function ClubRunIcon(props: ClubRunIconProps): JSX.Element {
  const { clubRun } = props;
  const runClubIconTag = <img className={`${styles.iconrunclub}`} src={`img/clubs-icons/${clubRun}.svg`} alt={`Иконка клуба ${clubRun}`} width={ClubIconSize.Width} height={ClubIconSize.Height} />;

  return (
    <>
      {(clubRun && ClubRuns.includes(clubRun)) ? runClubIconTag : ''}
    </>
  );
}

export default ClubRunIcon;
