import styles from './index.module.scss';
import { ClubIconSize } from '../../const';
import TableCellTShirt from '../TableCellTShirt/TableCellTShirt';

type ClubTableRowProps = {
  eventCount: number | undefined;
  iconCount: number;
  clubRunVolunteering: string | undefined;
  linkpostfix: string;
  gender: string;
}

function ClubTableRow(props: ClubTableRowProps): JSX.Element {
  const { eventCount, iconCount, clubRunVolunteering, linkpostfix, gender } = props;

  const showClubRow = (countEvent: number | undefined) => {
    if (countEvent) {
      if (countEvent >= iconCount) {
        return 'Вы в клубе';
      } else {
        return `Ещё ${iconCount - countEvent}...`;
      }
    } else if (countEvent === 0) {
      return `Ещё ${iconCount - countEvent}...`;
    }
  };

  return (
    <tr>
      <td className={styles.tcell}>
        <img src={`img/clubs-icons/${clubRunVolunteering}.svg`} alt={`Иконка клуба ${clubRunVolunteering}`} width={ClubIconSize.Width} height={ClubIconSize.Height} />
      </td>
      <td className={styles.tcell}>{showClubRow(eventCount)}</td>
      {iconCount !== 10 ? 
        <TableCellTShirt 
          colSpan={1} 
          eventCount={eventCount} 
          iconCount={iconCount} 
          iconName='t-shirt'
          tshirtPrefix=''
          linkpostfix={linkpostfix}
          gender={gender}
          altText='футболки'
        /> : 
        <TableCellTShirt 
          colSpan={2} 
          eventCount={eventCount} 
          iconCount={iconCount} 
          iconName='t-shirt'
          tshirtPrefix=''
          linkpostfix={linkpostfix}
          gender={gender}
          altText='футболки'
        />
      }
      {/* {iconCount !== 10 &&
        <TableCellTShirt 
          colSpan={1} 
          eventCount={eventCount} 
          iconCount={iconCount} 
          iconName='shirt'
          tshirtPrefix='t-shirt-'
          linkpostfix={linkpostfix}
          gender={gender}
          altText='майки'
        />
      } */}
    </tr>
  );
}

export default ClubTableRow;
