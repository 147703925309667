import styles from './index.module.scss';
import { ClubIconSize, ClubVolunteers } from '../../const';

type ClubVolunteeringIconProps = {
  clubVolunteering: string | undefined;
}

function ClubVolunteeringIcon(props: ClubVolunteeringIconProps): JSX.Element {
  const { clubVolunteering } = props;
  const volunteeringClubIconTag = <img className={`${styles.iconvolunteeringclub}`} src={`img/clubs-icons/${clubVolunteering}.svg`} alt={`Иконка клуба ${clubVolunteering}`} width={ClubIconSize.Width} height={ClubIconSize.Height} />;

  return (
    <>
      {(clubVolunteering && ClubVolunteers.includes(clubVolunteering)) ? volunteeringClubIconTag : ''}
    </>
  );
}

export default ClubVolunteeringIcon;
