import { createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import { APIRoute, ErrorCodes, WarningMessage } from "../../const";
import { api } from "../root-reducer";
import { 
  RzdBonusIdById, 
  RzdBonusIdSetData, 
  RzdBonusIdResponseType,
} from "./types";
import { getRzdBonusData, setDisabledInput } from "./slice";

export const getRzdBonusIdAction = createAsyncThunk(
  "data/rzdbonusid-get",
  async ({ id }: RzdBonusIdById, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.post(APIRoute.GetRzdBonusId, {
        id,
      });
      if (response.status === 200) {
        dispatch(setDisabledInput(true));
      }
      return response.data.result;
    } catch (error: any) {
      dispatch(setDisabledInput(false));
      switch (error.response.data.errorCode) {
        case ErrorCodes.InternalSystemError:
          notification.warn({ message: WarningMessage.InternalSystemError });
          break;
        // default:
        //   notification.warn({ message: WarningMessage.NetworkError });
      }

      return rejectWithValue(error.message);
    }
  }
);

export const setRzdBonusIdAction = createAsyncThunk(
  "data/rzdbonusid-set",
  async ({ rzd_bonus_id, is_agree }: RzdBonusIdSetData, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.post<RzdBonusIdResponseType>(APIRoute.SetRzdBonusId, {
        rzd_bonus_id,
        is_agree,
      });
      if (response.status === 200) {
        notification.success({ message: WarningMessage.RzdBonusIdSuccess });
        dispatch(getRzdBonusData(response.data.result));
        dispatch(setDisabledInput(true));
      }
      return response.data;
    } catch (error: any) {
      switch (error.response.data.errorCode) {
        case ErrorCodes.InternalSystemError:
          notification.warn({ message: WarningMessage.InternalSystemError });
          break;
        case ErrorCodes.DuplicateRzdBonusID:
          notification.warn({ message: WarningMessage.DuplicateRzdBonusIdError });
          break;
        default:
          notification.warn({ message: WarningMessage.NetworkError });
      }

      return rejectWithValue(error.message);
    }
  }
);
