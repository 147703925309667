import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AthleteReducer, AthleteData, EventsType } from "./types";
import {
  loadAthleteAction,
  loadEventsAction,
  updateAthleteAction
} from "./thunk";
import { AxiosError } from "axios";

const initialState: AthleteReducer = {
  athlete: null,
  isLoading: false,
  isLoaded: false,
  error: null,
  events: [],
  qrcode:'',
};

export const athleteSlice = createSlice({
  name: "AthleteSlice",
  initialState,
  reducers: {
    setAthleteData: (
      state: AthleteReducer,
      action: PayloadAction<AthleteData | null>
    ) => {
      state.athlete = action.payload;
    },
    setLoadStatus: (
      state: AthleteReducer,
      action: PayloadAction<boolean>
    ) => {
      state.isLoaded = action.payload;
    },
    setEventsData: (
      state: AthleteReducer,
      action: PayloadAction<EventsType>
    ) => {
      state.events = action.payload;
    },
    setAthleteLastName: (state: AthleteReducer, action: PayloadAction<string>) => {
      if (state.athlete) {
        state.athlete.last_name = action.payload;
      }
    },
    setAthleteFirstName: (
      state: AthleteReducer,
      action: PayloadAction<string>
    ) => {
      if (state.athlete) {
        state.athlete.first_name = action.payload;
      }
    },
    setAthleteMiddleName: (
      state: AthleteReducer,
      action: PayloadAction<string>
    ) => {
      if (state.athlete) {
        state.athlete.middle_name = action.payload;
      }
    },
    setAthleteIceName: (state: AthleteReducer, action: PayloadAction<string>) => {
      if (state.athlete) {
        state.athlete.ice_name = action.payload;
      }
    },
    setAthleteIceContact: (
      state: AthleteReducer,
      action: PayloadAction<string>
    ) => {
      if (state.athlete) {
        state.athlete.ice_contact = action.payload;
      }
    },
    setAthleteHomeEventId: (
      state: AthleteReducer,
      action: PayloadAction<number>
    ) => {
      if (state.athlete) {
        state.athlete.home_event_id = action.payload;
      }
    },
  },
  extraReducers: {
    [loadAthleteAction.pending.type]: (state: AthleteReducer) => {
      state.isLoading = true;
      state.error = null;
    },
    [loadAthleteAction.fulfilled.type]: (
      state: AthleteReducer,
      { payload }: PayloadAction<any>
    ) => {
      state.athlete = payload;
      state.isLoading = false;
      state.isLoaded = true;
    },
    [loadAthleteAction.rejected.type]: (
      state: AthleteReducer,
      { payload }: PayloadAction<AxiosError>
    ) => {
      state.athlete = null;
      state.isLoading = false;
      state.error = payload;
    },
    [loadEventsAction.pending.type]: (state: AthleteReducer) => {
      state.isLoading = true;
      state.error = null;
    },
    [loadEventsAction.fulfilled.type]: (
      state: AthleteReducer,
      { payload }: PayloadAction<any>
    ) => {
      state.events = payload;
      state.isLoading = false;
    },
    [loadEventsAction.rejected.type]: (
      state: AthleteReducer,
      { payload }: PayloadAction<AxiosError>
    ) => {
      state.events = [];
      state.isLoading = false;
      state.error = payload;
    },
    [updateAthleteAction.pending.type]: (state: AthleteReducer) => {
      state.isLoading = true;
      state.error = null;
    },
    [updateAthleteAction.fulfilled.type]: (
      state: AthleteReducer
    ) => {
      state.isLoading = false;
    },
    [updateAthleteAction.rejected.type]: (
      state: AthleteReducer,
      { payload }: PayloadAction<AxiosError>
    ) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const {
  setAthleteData,
  setLoadStatus,
  setEventsData,
  setAthleteLastName,
  setAthleteFirstName,
  setAthleteMiddleName,
  setAthleteIceName,
  setAthleteIceContact,
  setAthleteHomeEventId
} = athleteSlice.actions;
export default athleteSlice.reducer;
