import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RzdBonusIdReducer, RzdBonusData } from "./types";
import {
  getRzdBonusIdAction,
  setRzdBonusIdAction,
} from "./thunk";
import { AxiosError } from "axios";

const initialState: RzdBonusIdReducer = {
  rzdBbonus: {
    athlete_id: 0,
    rzd_bonus_id: '',
    is_active: false,
    status: '',
  },
  isInputDisabled: false,
  isCheckboxAgree: false,
  isLoading: false,
  isLoaded: false,
  error: null,
};

export const rzdBonusIdSlice = createSlice({
  name: "RzdBonusIdSlice",
  initialState,
  reducers: {
    setRzdBonusId: (
      state: RzdBonusIdReducer,
      action: PayloadAction<string>
    ) => {
      state.rzdBbonus.rzd_bonus_id = action.payload;
    },
    getRzdBonusData: (
      state: RzdBonusIdReducer,
      action: PayloadAction<RzdBonusData>
    ) => {
      state.rzdBbonus = action.payload;
    },
    setRzdBonusInitialState: (
      state: RzdBonusIdReducer
    ) => {
      state.rzdBbonus.athlete_id = 0;
      state.rzdBbonus.rzd_bonus_id = '';
      state.rzdBbonus.is_active = false;
      state.rzdBbonus.status = '';
      state.isCheckboxAgree = false;
    },
    setLoadRzdBonusStatus: (
      state: RzdBonusIdReducer,
      action: PayloadAction<boolean>
    ) => {
      state.isLoaded = action.payload;
    },
    setDisabledInput: (
      state: RzdBonusIdReducer,
      action: PayloadAction<boolean>
    ) => {
      state.isInputDisabled = action.payload;
    },
    setCheckboxAgree: (
      state: RzdBonusIdReducer,
      action: PayloadAction<boolean>
    ) => {
      state.isCheckboxAgree = action.payload;
    },
  },
  extraReducers: {
    [getRzdBonusIdAction.pending.type]: (state: RzdBonusIdReducer) => {
      state.isLoading = true;
      state.error = null;
    },
    [getRzdBonusIdAction.fulfilled.type]: (
      state: RzdBonusIdReducer,
      { payload }: PayloadAction<any>
    ) => {
      state.rzdBbonus = payload;
      state.isLoading = false;
      state.isLoaded = true;
    },
    [getRzdBonusIdAction.rejected.type]: (
      state: RzdBonusIdReducer,
      { payload }: PayloadAction<AxiosError>
    ) => {
      state.rzdBbonus = {
        athlete_id: 0,
        rzd_bonus_id: '',
        is_active: false,
        status: '',
      };
      state.isLoading = false;
      state.error = payload;
    },
    [setRzdBonusIdAction.pending.type]: (state: RzdBonusIdReducer) => {
      state.isLoading = true;
      state.error = null;
    },
    [setRzdBonusIdAction.fulfilled.type]: (
      state: RzdBonusIdReducer
    ) => {
      state.isLoading = false;
    },
    [setRzdBonusIdAction.rejected.type]: (
      state: RzdBonusIdReducer,
      { payload }: PayloadAction<AxiosError>
    ) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { 
  getRzdBonusData, 
  setRzdBonusId, 
  setLoadRzdBonusStatus,
  setRzdBonusInitialState,
  setDisabledInput,
  setCheckboxAgree,
} = rzdBonusIdSlice.actions;
export default rzdBonusIdSlice.reducer;
