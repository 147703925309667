import { NameSpace } from "../root-reducer";
import { State } from "../../types/state";
import { EventsType, PersonalBestType, BonusProgramType } from "./types";

export const getAthleteFirstName = (state: State): string | undefined =>
  state[NameSpace.athlete].athlete?.first_name;
export const getAthleteLastName = (state: State): string | undefined =>
  state[NameSpace.athlete].athlete?.last_name;
export const getAthleteMiddleName = (state: State): string | undefined =>
  state[NameSpace.athlete].athlete?.middle_name;
export const getAthleteQRcode = (state: State): string | undefined =>
  state[NameSpace.athlete].athlete?.qr_code;
export const getAthleteIceName = (state: State): string | undefined =>
  state[NameSpace.athlete].athlete?.ice_name;
export const getAthletePhone = (state: State): string | undefined =>
  state[NameSpace.athlete].athlete?.phone;
export const getAthleteIceContact = (state: State): string | undefined =>
  state[NameSpace.athlete].athlete?.ice_contact;
export const getAthleteId = (state: State): number | undefined =>
  state[NameSpace.athlete].athlete?.verst_id;
export const getParkrunId = (state: State): number | undefined =>
  state[NameSpace.athlete].athlete?.parkrun_id;
export const getRunCount = (state: State): number | undefined =>
  state[NameSpace.athlete].athlete?.run_count;
export const getVolunteeringSaturdays = (state: State): number | undefined =>
  state[NameSpace.athlete].athlete?.volunteering_saturdays;
export const getPersonalBest = (state: State): PersonalBestType | undefined =>
  state[NameSpace.athlete].athlete?.personal_best;
export const getAthleteHomeEventId = (state: State): number | undefined =>
  state[NameSpace.athlete].athlete?.home_event_id;
export const getEvents = (state: State): EventsType =>
  state[NameSpace.athlete].events;
export const getBonusPrograms = (state: State): BonusProgramType[] | undefined=>
  state[NameSpace.athlete].athlete?.bonus_programs;

export const getIsLoading = (state: State) => state[NameSpace.athlete].isLoading;
export const getIsAthleteLoaded = (state: State): boolean =>
  state[NameSpace.athlete].isLoaded;
