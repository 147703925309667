import { Link } from "react-router-dom";
import { AppRoute, AuthorizationStatus } from "../../const";
import { getAuthorizationStatus } from "../../store/user/selectors";
import { useAppSelector } from "../../customHooks/useAppSelector";
import styles from './index.module.scss';

function NotFoundScreen(): JSX.Element {
  const authorizationStatus = useAppSelector(getAuthorizationStatus);

  return (
    <div className={styles.notfound}>
      <section>
        <h1>404. Страница не найдена</h1>
        <Link to={authorizationStatus === AuthorizationStatus.Auth ? AppRoute.Home : AppRoute.Login}>Вернуться на главную</Link>
      </section>
    </div>
  );
}

export default NotFoundScreen;
