import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  getFinishTimes,
  getEventDates,
  getEventNames
} from '../../store/statistics/selectors';
import { useAppSelector } from '../../customHooks/useAppSelector';
import { AppRoute, paceDataToShow } from '../../const';
import styles from './index.module.scss';
import LineChart from '../../components/Charts/LineChart';
import {
  getPace,
  getChartData,
  getMaxRoundElement,
  getMinRoundElement,
  getDataChunk
} from '../../utils';

function ChartPaceScreen(): JSX.Element {
  const athleteResults = useAppSelector(getFinishTimes);
  const eventDates = useAppSelector(getEventDates);
  const eventNames = useAppSelector(getEventNames);
  const athletePaces = athleteResults?.map((result) => getPace(result));

  const results = getChartData(athletePaces, eventDates, eventNames);

  // *****************  Mock data *********************
  /* const athletePaces = [
    '4:21', '5:24', '5:07', '5:32', '5:37', '4:21',
    '5:24', '5:07', '5:32', '5:37', '4:21', '5:24',
    '5:07', '5:32', '5:37', '4:21', '5:24', '5:07',
    '5:32', '5:37', '4:21', '5:24', '5:07', '5:32',
    '5:37', '4:21', '5:24', '5:07', '5:32', '5:37',
    '4:21', '5:24', '5:07', '5:32', '5:37', '4:21',
    '5:24', '5:07', '5:32', '5:37', '4:21', '5:24',
    '5:07', '5:32', '5:37', '4:21', '5:24', '5:07',
    '5:32', '5:37', '5:07'
  ];

  const eventDates = [
    '01.12.2022',
    '29.10.2022', '22.10.2022', '15.10.2022', '08.10.2022', '01.10.2022', 
    '29.09.2022', '22.09.2022', '15.09.2022', '08.09.2022', '01.09.2022',
    '29.08.2022', '22.08.2022', '15.08.2022', '08.08.2022', '01.08.2022',
    '29.07.2022', '22.07.2022', '15.07.2022', '08.07.2022', '01.07.2022',
    '29.06.2022', '22.06.2022', '15.06.2022', '08.06.2022', '01.06.2022',
    '29.05.2022', '22.05.2022', '15.05.2022', '08.05.2022', '01.05.2022',
    '29.04.2022', '22.04.2022', '15.04.2022', '08.04.2022', '01.04.2022',
    '29.03.2022', '22.03.2022', '15.03.2022', '08.03.2022', '01.03.2022',
    '29.02.2022', '22.02.2022', '15.02.2022', '08.02.2022', '01.02.2022',
    '29.01.2022', '22.01.2022', '15.01.2022', '08.01.2022', '01.01.2022',
  ];

  const eventNames = [
    'Филатов Луг',
    'Филатов Луг', 'Филатов Луг', 'Филатов Луг', 'Филатов Луг', 'Филатов Луг',
    'Филатов Луг', 'Филатов Луг', 'Филатов Луг', 'Филатов Луг', 'Филатов Луг',
    'Филатов Луг', 'Филатов Луг', 'Филатов Луг', 'Филатов Луг', 'Филатов Луг',
    'Филатов Луг', 'Филатов Луг', 'Филатов Луг', 'Филатов Луг', 'Филатов Луг',
    'Филатов Луг', 'Филатов Луг', 'Филатов Луг', 'Филатов Луг', 'Филатов Луг',
    'Филатов Луг', 'Филатов Луг', 'Филатов Луг', 'Филатов Луг', 'Филатов Луг',
    'Филатов Луг', 'Филатов Луг', 'Филатов Луг', 'Филатов Луг', 'Филатов Луг',
    'Филатов Луг', 'Филатов Луг', 'Филатов Луг', 'Филатов Луг', 'Филатов Луг',
    'Филатов Луг', 'Филатов Луг', 'Филатов Луг', 'Филатов Луг', 'Филатов Луг',
    'Филатов Луг', 'Филатов Луг', 'Филатов Луг', 'Филатов Луг', 'Филатов Луг',
  ]; */
  // *************************************************

  const additionalChartCaptionText = `(последние ${paceDataToShow} стартов)`;

  const chunkResults = (results && results.length > paceDataToShow) ? getDataChunk(results, paceDataToShow) : results;

  const [ resultsToShow, setResultsToShow ] = useState(chunkResults);
  const [ buttonText, setButtonText ] = useState('все старты');
  const [ isAllResultsToShow, setIsAllResultsToShow ] = useState(false);
  const [ additionalText, setAdditionalText ] = useState(additionalChartCaptionText);

  const buttonClickHandler = (evt: React.MouseEvent) => {
    evt.preventDefault();
    if (!isAllResultsToShow) {
      setResultsToShow(results);
      setIsAllResultsToShow(true);
      setAdditionalText('');
      setButtonText(`${paceDataToShow} последних`);
    } else {
      setResultsToShow(chunkResults);
      setIsAllResultsToShow(false);
      setButtonText('все старты');
      setAdditionalText(additionalChartCaptionText);
    }
  };

  return (
    <div className={styles.chartblock}>
      <h2 className={styles.chartcaption}>График темпа {results && results.length > paceDataToShow && additionalText}</h2>
      <LineChart
        chartData={resultsToShow}
        maxPace={getMaxRoundElement(athletePaces)}
        minPace={getMinRoundElement(athletePaces)}
        xAxeTicks={eventDates}
      />
      {results && results.length > paceDataToShow && <button 
        className={styles.chatdatatoggle}
        onClick={buttonClickHandler}
      >Показать {buttonText}</button>}
      <p className={styles.statslink}>Вернуться к <Link to={AppRoute.Stats}>статистике</Link></p>
    </div>
  );
}

export default ChartPaceScreen;
