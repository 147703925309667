import { Link, useLocation } from "react-router-dom";
import { AppRoute } from "../../../const";
import { useAppDispatch } from '../../../customHooks/useAppDispatch';
import { useAppSelector } from '../../../customHooks/useAppSelector';
import { getPasswordStatus } from '../../../store/password/selectors';
import { setPasswordChangeStatus } from '../../../store/password/slice';
import styles from "./index.module.scss";

function Menu(): JSX.Element {
  const location = useLocation();
  const isPasswordChanged = useAppSelector(getPasswordStatus);

  const dispatch = useAppDispatch();

  const handleStatsButton = () => {
    if (location.pathname === AppRoute.UpdatePassword && isPasswordChanged) {
      dispatch(setPasswordChangeStatus(false));
    }
  }

  const handleMenuItem = () => {
    if (isPasswordChanged) {
      dispatch(setPasswordChangeStatus(false));
    }
  }

  return (
    <nav className={styles.wrapper}>
      <Link
        to={AppRoute.Home}
        className={location.pathname === AppRoute.Home ? styles.active : ""}
        onClick={handleMenuItem}
      >
        Главная
      </Link>
      <Link
        to={AppRoute.QRcode}
        className={
          location.pathname === AppRoute.QRcode ? styles.active : ""
        }
        onClick={handleMenuItem}
      >
        QR-код
      </Link>
      <Link
        to={AppRoute.Personal}
        className={
          location.pathname === AppRoute.Personal ? styles.active : ""
        }
        onClick={handleMenuItem}
      >
        Данные
      </Link>
      <Link
        to={AppRoute.Stats}
        className={
          location.pathname === AppRoute.Stats ? styles.active : ""
        }
        onClick={handleStatsButton}
      >
        Статистика
      </Link>
      <Link
        to={AppRoute.UpdatePassword}
        className={
          location.pathname === AppRoute.UpdatePassword ? styles.active : ""
        }
      >
        Пароль
      </Link>
      <Link
        to={AppRoute.RzdBonusId}
        className={location.pathname === AppRoute.RzdBonusId ? styles.active : ""}
      >
        РЖД Бонус
      </Link>
      <Link
        to={AppRoute.SportmasterBonus}
        className={location.pathname === AppRoute.SportmasterBonus ? styles.active : ""}
      >
        Спортмастер
      </Link>
      <Link
        to={AppRoute.Clubs}
        className={
          location.pathname === AppRoute.Clubs ? styles.active : ""
        }
      >
        Клубы
      </Link>
    </nav>
  );
}

export default Menu;
