import { NameSpace } from "../root-reducer";
import { State } from "../../types/state";

export const getRzdBonusId = (state: State): string | undefined =>
  state[NameSpace.rzdbonus].rzdBbonus?.rzd_bonus_id;
export const getRzdBonusAthleteId = (state: State): number | undefined =>
  state[NameSpace.rzdbonus].rzdBbonus?.athlete_id;
export const getRzdBonusStatus = (state: State): boolean | undefined =>
  state[NameSpace.rzdbonus].rzdBbonus?.is_active;
export const getRzdBonusIDRequestStatus = (state: State): string | undefined =>
  state[NameSpace.rzdbonus].rzdBbonus?.status;
export const getDisabledInputStatus = (state: State): boolean =>
  state[NameSpace.rzdbonus].isInputDisabled;
export const getCheckboxAgreeStatus = (state: State): boolean =>
  state[NameSpace.rzdbonus].isCheckboxAgree;

export const getIsRzdBonusIdLoading = (state: State) => state[NameSpace.rzdbonus].isLoading;
export const getIsRzdBonusIdLoaded = (state: State): boolean =>
  state[NameSpace.rzdbonus].isLoaded;
