import { useLocation } from "react-router-dom";
import Banner from "../Banner/Banner";
import { Banners, AppRoute } from "../../const";
import styles from "./index.module.scss";

function Footer(): JSX.Element {
  const location = useLocation();
  const appRouteValues: String[] = Object.values(AppRoute);
  
  const indexBannerList = Math.floor(Math.random() * Banners.length);
  const indexSubBunnerList = Math.floor(Math.random() * Banners[indexBannerList].length);
  const currentBanner = Banners[indexBannerList][indexSubBunnerList];

  if (
    location.pathname === AppRoute.Login ||
    location.pathname === AppRoute.RemindPassword ||
    appRouteValues.indexOf(location.pathname) === -1
  )
    return <></>;

  return (
    <footer className={styles.footer}>
      <Banner Banner={currentBanner} />
    </footer>
  );
}

export default Footer;