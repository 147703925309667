import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { AuthorizationStatus } from "../../const";
import { loginAction } from "./thunk";
import { UserReducer } from "./types";

const initialState: UserReducer = {
  loading: false,
  error: null,
  token: null,
  authorizationStatus: AuthorizationStatus.Unknown,
};

export const userSlice = createSlice({
  name: "UserSlice",
  initialState,
  reducers: {
    userLogout: (state: UserReducer) => {
      state.authorizationStatus = AuthorizationStatus.NoAuth;
      state.token = null;
    },
    setTokenFromStorage: (
      state: UserReducer,
      action: PayloadAction<string>
    ) => {
      state.token = { token: action.payload };
      state.authorizationStatus = AuthorizationStatus.Auth;
    },
  },
  extraReducers: {
    [loginAction.pending.type]: (state: UserReducer) => {
      state.loading = true;
      state.error = null;
    },
    [loginAction.fulfilled.type]: (
      state: UserReducer,
      { payload }: PayloadAction<any>
    ) => {
      state.token = payload;
      state.authorizationStatus = AuthorizationStatus.Auth;
      state.loading = false;
    },
    [loginAction.rejected.type]: (
      state: UserReducer,
      { payload }: PayloadAction<AxiosError>
    ) => {
      state.token = null;
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { userLogout, setTokenFromStorage } = userSlice.actions;
export default userSlice.reducer;
