import { FormEvent, ChangeEvent, useState, useRef, useEffect } from 'react';
import styles from './index.module.scss';
import { Spin, notification } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { updatePasswordAction } from '../../store/password/thunk';
import { useAppDispatch } from "../../customHooks/useAppDispatch";
import { useAppSelector } from "../../customHooks/useAppSelector";
import { getPasswordStatus, getIsLoading } from '../../store/password/selectors';
import { setPasswordChangeStatus } from '../../store/password/slice';
import { WarningMessage, passwordRegEx } from '../../const';
import PasswordChecklist from "react-password-checklist";

function UpdatePasswordScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const isPasswordChanged = useAppSelector(getPasswordStatus);
  const isLoading = useAppSelector(getIsLoading);

  const passwordOldRef = useRef<HTMLInputElement | null>(null);
  const passwordNewRef = useRef<HTMLInputElement | null>(null);
  const passwordRepeatRef = useRef<HTMLInputElement | null>(null);
  const buttonSetPasswordRef = useRef<HTMLButtonElement | null>(null);
  const linkToHomePageRef = useRef<HTMLAnchorElement | null>(null);

  const [ passwordOld, setPasswordOld ] = useState("");
  const [ passwordNew, setPasswordNew ] = useState("");
  const [ passwordRepeat, setPasswordRepeat ] = useState("");
  const [ passwordOldType, setPasswordOldType ] = useState("password");
  const [ passwordNewType, setPasswordNewType ] = useState("password");
  const [ passwordRepeatType, setPasswordRepeatType ] = useState("password");

  useEffect(() => passwordOldRef.current?.focus(), []);

  const getFocus = () => {
    const focusableElements = [
      passwordOldRef.current,
      passwordNewRef.current,
      passwordRepeatRef.current,
      buttonSetPasswordRef.current,
      linkToHomePageRef.current
    ];
    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement = focusableElements[focusableElements.length - 1];

    document.addEventListener('keydown', (evt: KeyboardEvent) => {
      const isTabPressed = evt.key === 'Tab';

      if (!isTabPressed) {
        return;
      }

      if (evt.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement?.focus();
          evt.preventDefault();
        }
      } else {
        if (document.activeElement === lastFocusableElement) {
          firstFocusableElement?.focus();
          evt.preventDefault();
        }
      }
    });
  };

  useEffect(() => {
    getFocus();
  });

  const handlePasswordOldChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setPasswordOld(evt.target.value);
  };

  const handlePasswordNewChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setPasswordNew(evt.target.value);
  };

  const handlePasswordRepeatChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setPasswordRepeat(evt.target.value);
  };

  const handleSubmit = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    if (!passwordNew.match(passwordRegEx)) {
      notification.warn({ message: 'Проверьте правильность нового пароля' });
    }

    if (passwordOld && passwordNew && passwordRepeat) {
      if (passwordNew === passwordRepeat) {
        dispatch(updatePasswordAction({
          old_password: passwordOld,
          new_password: passwordNew
        }))
      } else {
        notification.warn({ message: WarningMessage.PasswordsNotIdentical });
      }

    } else {
      notification.warn({message: 'Поля паролей не могут быть пустыми'})
    }
    
  };

  const togglePasswordOldType = () => {
    if (passwordOldType === "text") {
      setPasswordOldType("password");
    } else {
      setPasswordOldType("text");
    }
  };

  const togglePasswordNewType = () => {
    if (passwordNewType === "text") {
      setPasswordNewType("password");
    } else {
      setPasswordNewType("text");
    }
  };

  const togglePasswordRepeatType = () => {
    if (passwordRepeatType === "text") {
      setPasswordRepeatType("password");
    } else {
      setPasswordRepeatType("text");
    }
  };

  const handleLinkClick = () => {
    dispatch(setPasswordChangeStatus(false));
  };

  return (
    <div className={styles.mainwrapper}>
      <section className={styles.page}>
        {!isPasswordChanged && <form
          className="password-reminder"
          onSubmit={handleSubmit}
        >
          <div className={`${styles.wrapper} ${styles.newpassword}`}>
            <label className={styles.label} htmlFor="old-password">
              Введите старый пароль:
            </label>
            <input
              className={styles.input}
              value={passwordOld}
              type={passwordOldType}
              name="password-old"
              id="old-password"
              placeholder="Старый пароль"
              onChange={handlePasswordOldChange}
              ref={passwordOldRef}
            />
            <span className={styles.eye} onClick={togglePasswordOldType}>
              {passwordOldType === "password" ? (
                <span>
                  <EyeOutlined />
                </span>
              ) : (
                <EyeInvisibleOutlined />
              )}
            </span>
          </div>
          <div className={`${styles.wrapper} ${styles.newpassword}`}>
            <label className={styles.label} htmlFor="new-password">
              Введите новый пароль:
            </label>
            <input
              className={styles.input}
              value={passwordNew}
              type={passwordNewType}
              name="password-new"
              id="new-password"
              placeholder="Новый пароль"
              onChange={handlePasswordNewChange}
              ref={passwordNewRef}
            />
            <span className={styles.eye} onClick={togglePasswordNewType}>
              {passwordNewType === "password" ? (
                <span>
                  <EyeOutlined />
                </span>
              ) : (
                <EyeInvisibleOutlined />
              )}
            </span>
          </div>
          <PasswordChecklist
            rules={["number", "lowercase", "capital", "minLength"]}
            minLength={8}
            value={passwordNew}
            messages={{
              number: "Пароль содержит цифру",
              lowercase: "Пароль содержит строчную букву",
              capital: "Пароль содержит заглавную букву",
              minLength: "Пароль длиннее 8 символов",
            }}
          />
          <div className={`${styles.wrapper} ${styles.repeatpassword}`}>
            <label className={styles.label} htmlFor="repeat-password">
                Введите новый пароль еще раз:
              </label>
              <input
                className={styles.input}
                value={passwordRepeat}
                type={passwordRepeatType}
                name="password-repeat"
                id="repeat-password"
                placeholder="Повторите пароль"
                onChange={handlePasswordRepeatChange}
                ref={passwordRepeatRef}
              />
              <span className={styles.eye} onClick={togglePasswordRepeatType}>
                {passwordRepeatType === "password" ? (
                  <span>
                    <EyeOutlined />
                  </span>
                ) : (
                  <EyeInvisibleOutlined />
                )}
              </span>
          </div>
          {isLoading ? (
            <div className={styles.spinner}>
              <Spin size="large" />
            </div>
          ) : null}
          <button
            className={styles.button}
            ref={buttonSetPasswordRef}
            onClick={handleLinkClick}
          >
            Изменить пароль
          </button>
        </form>}
        {isPasswordChanged && <h2>Пароль успешно изменен</h2>}
      </section>
    </div>
  )
}

export default UpdatePasswordScreen;

// module.scss two classes
// className={`${styles.description} ${styles.yellow}`}