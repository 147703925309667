import styles from './index.module.scss';

type BannerProps = {
  Banner: {
    id: string;
    name: string;
    src_desktop: string;
    src_tablet: string;
    src_mobile: string;
    url: string;
    alt: string;
  }
}

function Banner(props: BannerProps): JSX.Element {
  const {  
    src_desktop,
    src_tablet,
    src_mobile,
    url, 
    alt
  } = props.Banner;

  return (
      <a className={styles.banner} href={url} target="_blank" rel="noreferrer">
        <picture>
          <source media='(min-width: 936px)' srcSet={src_desktop}/>
          <source media='(min-width: 768px)' srcSet={src_tablet}/>
          <img className={styles.bannerimg} src={src_mobile} alt={alt} />
        </picture>
      </a>
  );
}

export default Banner;