/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import styles from "./index.module.scss";
import { ChangeEvent, FormEvent, useState, useRef, useEffect } from "react";
import { Checkbox, CheckboxProps } from "antd";
import { useAppSelector } from "../../customHooks/useAppSelector";
import { useAppDispatch } from "../../customHooks/useAppDispatch";
import { getAthletePhone, getBonusPrograms } from "../../store/athlete/selectors";
import { ExternalLink, Partners } from "../../const";
import { validatePhoneLength } from "../../utils";
import { joinSportmasterProgramAction, updateSportmasterProgramAction } from "../../store/sportmasterbonus/thunk";
import { getJoinSMStatus, getJoinAgreeStatus } from "../../store/sportmasterbonus/selectors";
import { setJoinAgreeStatus, setJoinSMStatus } from "../../store/sportmasterbonus/slice";
import { IMaskInput } from 'react-imask';

function SportmasterBonusScreen(): JSX.Element {
  const dispatch = useAppDispatch();

  const athletePhone = useAppSelector(getAthletePhone) as string;
  const isJoinSM = useAppSelector(getJoinSMStatus);
  const isJoinAgree = useAppSelector(getJoinAgreeStatus);
  const buttonSubmitRef = useRef<HTMLButtonElement | null>(null);
  const athletePhoneRef = useRef<HTMLInputElement | null>(null);

  const bonusPrograms = useAppSelector(getBonusPrograms);
  const sportmasterBonusProgram = bonusPrograms && bonusPrograms[0];
  const isActiveSMBonusProgram = sportmasterBonusProgram?.is_active;

  const [phone, setPhone] = useState(athletePhone);

  useEffect(() => {
    if (isActiveSMBonusProgram) {
      dispatch(setJoinSMStatus(true));
    }
    dispatch(setJoinAgreeStatus(localStorage.getItem('isJoinAgree') === 'true'));
  }, []);

  const onChangeCheckbox: CheckboxProps['onChange'] = (e) => {
    dispatch(setJoinAgreeStatus(!isJoinAgree));
    !isJoinAgree ? localStorage.setItem('isJoinAgree', 'true') : localStorage.setItem('isJoinAgree', 'false');
  };

  const handlePhoneInput = (evt: ChangeEvent<HTMLInputElement>): void => {
    if (evt.target === athletePhoneRef.current) {
      athletePhoneRef.current.setCustomValidity(validatePhoneLength(athletePhoneRef.current.value));
      athletePhoneRef.current.reportValidity();
    }
  }

  const handleSubmit = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (!isJoinSM) {
      dispatch(
        joinSportmasterProgramAction({
          phone: phone,
          partner_name: Partners.Sportmaster,
          personal_data_processing: true,
        })
      );
    } else {
      dispatch(updateSportmasterProgramAction({
        is_active: false,
      }));
    }
  };

  return (
    <div className={styles.mainwrapper}>
      <section className={styles.page}>
        <h2>Специальные условия от клубной программы Спортмастер участникам 5 вёрст.</h2> 
        <p className={styles.textone}>Участники 5 вёрст получают бонусы в клубной программе Спортмастер
         и могут тратить их при совершении покупок.</p>
        <h3 className={styles.heading}>Ваш мобильный телефон:</h3>
        <form 
          className="personal-data"
          onSubmit={handleSubmit}
        >
          <div className={styles.block}>
            <div className={styles.wrapper}>
              <IMaskInput
                className={styles.input}
                mask="+7(000)000-00-00"
                value={phone}
                type="text"
                ref={athletePhoneRef}
                onAccept={setPhone}
                onBlur={handlePhoneInput}
                placeholder="+7(000)000-00-00"
                tabIndex={2}
                disabled={isJoinSM && isActiveSMBonusProgram}
              />
            </div>
          </div>
          <div>
            {isJoinSM ?  
            (<p>
              Для вас действуют специальные условия по начислению бонусов участника 
              <a href={ExternalLink.SportmasteClubrUrl} target="_blank" rel="noreferrer"> Клубной программы «Спортмастер».</a><br />
                Для начисления бонусов ваш мобильный телефон должен быть зарегистрирован в Клубной программе «Спортмастер».
            </p>) : (<Checkbox 
              className={styles.checkbox}
              onChange={onChangeCheckbox} 
              checked={isJoinAgree}
              tabIndex={3}
              disabled={isJoinSM}
            >
              Я даю согласие на обработку, хранение, сбор, систематизацию и передачу персональных 
              данных ООО «Спортмастер» (ОГРН 1057747320278) с целью начисления бонусов участника 
              <a href={ExternalLink.SportmasteClubrUrl} target="_blank" rel="noreferrer"> Клубной программы «Спортмастер»</a>
            </Checkbox>)}
            {isJoinSM ? 
            (<button 
              className={styles.buttondecline}
              ref={buttonSubmitRef}
              tabIndex={4}
            >
              Отказаться
            </button>
            ) : (<button 
              className={styles.button}
              ref={buttonSubmitRef}
              disabled={!isJoinAgree}
              tabIndex={4}
            >
              Участвовать
            </button>
            )}
          </div>
        </form>
      </section>
    </div>
  );
}

export default SportmasterBonusScreen;
