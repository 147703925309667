import MainContent from "../../components/MainContent/MainContent";
import { useEffect } from 'react';
import { useAppDispatch } from '../../customHooks/useAppDispatch';
import { useAppSelector } from '../../customHooks/useAppSelector';
import { getAthleteId } from '../../store/athlete/selectors';
import { loadStatisticsAction } from '../../store/statistics/thunk';

function MainScreen(): JSX.Element {
    const dispatch = useAppDispatch();
    const athleteID = useAppSelector(getAthleteId);

    useEffect(() => {
        if (athleteID) {
        dispatch(
            loadStatisticsAction({
            id: athleteID,
            })
        );
        }

    }, [dispatch, athleteID]);
    return (
        <MainContent />
    );
}

export default MainScreen;