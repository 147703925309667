import { createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import { APIRoute, ErrorCodes, WarningMessage } from "../../const";
import { api } from "../root-reducer";
import { StatsById, StatsResponseType } from "./types";

export const loadStatisticsAction = createAsyncThunk(
  "stats/athlete-statsdata",
  async ({ id }: StatsById, { rejectWithValue }) => {
    try {
      const { data } = await api.post<StatsResponseType>(APIRoute.Stats, {
        id,
      });
      return data.result;
    } catch (error: any) {
      if (error.code === ErrorCodes.NetworkError) {
        notification.warn({ message: WarningMessage.NetworkError });
      }
      return rejectWithValue(error.message);
    }
  }
);
