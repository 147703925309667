import { createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import { APIRoute, ErrorCodes, WarningMessage } from "../../const";
import { saveToken } from "../../services/token";
import { api } from "../root-reducer";
import { AuthData, TUserResponse } from "./types";

// export const checkAuthAction = createAsyncThunk(
//   "user/checkAuth",
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await api.post(APIRoute.Login);
//       if (response.status === SERVER_RESPONSE_OK) {
//         return response.data;
//       }
//     } catch (error: any) {
//       // notification.error({ message: WarningMessage.RolesLoadFail });
//       return rejectWithValue(error.message);
//     }
//   }
// );

export const loginAction = createAsyncThunk(
  "user/login",
  async ({ username, password }: AuthData, { rejectWithValue }) => {
    try {
      const response = await api.post<TUserResponse>(APIRoute.Login, {
        username,
        password,
      });
      saveToken(response.data.result.token);
      return response.data.result.token;
      // dispatch(redirectToRoute(AppRoute.Home));
    } catch (error: any) {
      // console.log(
      //   "ОШИБКА ОТВЕТА: ",
      //   error.message,
      //   error.status,
      //   error.response,
      //   error
      // );
      if (error.code === ErrorCodes.NetworkError) {
        notification.warn({ message: WarningMessage.NetworkError });
      } else if (
        error.response.data.errorCode === ErrorCodes.WrongLoginPassword
      ) {
        notification.warn({ message: WarningMessage.WrongPassword });
      } else if (error.response.data.errorCode === ErrorCodes.AccessDenied) {
        notification.warn({ message: WarningMessage.AccessDenied });
      } else {
        notification.warn({ message: WarningMessage.SigninFail });
      }

      return rejectWithValue(error.message);
    }
  }
);

