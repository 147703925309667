/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./index.module.scss";
import { Spin, Checkbox } from "antd";
import { FormEvent } from "react";
import { useAppSelector } from "../../customHooks/useAppSelector";
import { getIsLoading } from "../../store/athlete/selectors";

function EmailSettingsScreen(): JSX.Element {
  const isLoading = useAppSelector(getIsLoading);

  const handleSubmit = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

  };

  return (
    <>
      <h1 className="visually-hidden">Страница управления рассылками</h1>
      <section className={styles.page}>
        <h2 className={styles.heading}>Отметьте нужные опции:</h2>
        <form className="email-settings" onSubmit={handleSubmit}>
          <div className={styles.checkbox}>
            <Checkbox>Я хочу быть в курсе новостей движения и согласен получать новостную рассылку</Checkbox>
          </div>
          <div className={styles.checkbox}>
            <Checkbox>Я хочу помогать с организацией мероприятий и согласен получать информацию от местной команды организаторов по электронной почте</Checkbox>

          </div>
          
          {isLoading ? (
            <div className={styles.spinner}>
              <Spin size="large" />
            </div>
          ) : null}
          <button className={styles.button}>Отправить</button>
        </form>
      </section>
    </>
  );
}

export default EmailSettingsScreen;
