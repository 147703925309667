
import { Link } from 'react-router-dom';
import { getStatsVolunteerings } from '../../store/statistics/selectors';
import { useAppSelector } from '../../customHooks/useAppSelector';
import { AppRoute } from '../../const';
import { getUniqueItems, getArrayOfUniqueItemsQuantities } from '../../utils';
import DoughnutChartMobile from '../../components/Charts/DoughnutChartMobile';
import styles from './index.module.scss';

function ChartVolunteeringScreen(): JSX.Element {
  const volunteeringsEvents = useAppSelector(getStatsVolunteerings);
  const volunteerRoles = volunteeringsEvents?.map((event) => event.volunteer_role_name);
  const uniqueVolunteerRoles = getUniqueItems(volunteerRoles);
  const volunteerings = getArrayOfUniqueItemsQuantities(volunteerRoles, uniqueVolunteerRoles);

  // *****************  Mock data *********************
  // const uniqueVolunteerRoles = [
  //   'Организатор',
  //   'Маршал',
  //   'Раздача карточек позиций',
  //   'Сканирование штрих-кодов',
  //   'Секундомер',
  //   'Замыкающий',
  //   'Фотограф',
  //   'Организация мероприятия',
  //   'Обработка результатов',
  //   'Составление отчета',
  //   'Пейсер',
  //   'Проверка трассы',
  //   'Проведение разминки',
  //   'Инструктаж новых участников',
  //   'Подготовка мероприятия',
  //   'Координация волонтеров',
  //   'Разное'
  // ];

  // const volunteerings = [10, 4, 7, 8, 15, 4, 3, 7, 9, 13, 3, 8, 4, 7, 10, 5, 7];

  /* const volunteeringsEvents = [
    {
      event_name: "Битца",
      event_date: "04.06.2022",
      volunteer_role_name: "Организатор",
      event_status: {
        id: 1,
        name: "готовится к запуску"
      },
      event_url: "bitsa"
    },
    {
      event_name: "Битца",
      event_date: "04.06.2022",
      volunteer_role_name: "Организатор",
      event_status: {
        id: 1,
        name: "готовится к запуску"
      },
      event_url: "bitsa"
    },
    {
      event_name: "Битца",
      event_date: "04.06.2022",
      volunteer_role_name: "Организатор",
      event_status: {
        id: 1,
        name: "готовится к запуску"
      },
      event_url: "bitsa"
    },
    {
      event_name: "Битца",
      event_date: "04.06.2022",
      volunteer_role_name: "Организатор",
      event_status: {
        id: 1,
        name: "готовится к запуску"
      },
      event_url: "bitsa"
    },
    {
      event_name: "Битца",
      event_date: "04.06.2022",
      volunteer_role_name: "Организатор",
      event_status: {
        id: 1,
        name: "готовится к запуску"
      },
      event_url: "bitsa"
    },
    {
      event_name: "Битца",
      event_date: "04.06.2022",
      volunteer_role_name: "Организатор",
      event_status: {
        id: 1,
        name: "готовится к запуску"
      },
      event_url: "bitsa"
    },
    {
      event_name: "Битца",
      event_date: "04.06.2022",
      volunteer_role_name: "Организатор",
      event_status: {
        id: 1,
        name: "готовится к запуску"
      },
      event_url: "bitsa"
    },
    {
      event_name: "Битца",
      event_date: "04.06.2022",
      volunteer_role_name: "Организатор",
      event_status: {
        id: 1,
        name: "готовится к запуску"
      },
      event_url: "bitsa"
    },
    {
      event_name: "Битца",
      event_date: "04.06.2022",
      volunteer_role_name: "Организатор",
      event_status: {
        id: 1,
        name: "готовится к запуску"
      },
      event_url: "bitsa"
    },
    {
      event_name: "Битца",
      event_date: "04.06.2022",
      volunteer_role_name: "Организатор",
      event_status: {
        id: 1,
        name: "готовится к запуску"
      },
      event_url: "bitsa"
    },
    {
      event_name: "Битца",
      event_date: "04.06.2022",
      volunteer_role_name: "Организатор",
      event_status: {
        id: 1,
        name: "готовится к запуску"
      },
      event_url: "bitsa"
    },
    {
      event_name: "Битца",
      event_date: "04.06.2022",
      volunteer_role_name: "Организатор",
      event_status: {
        id: 1,
        name: "готовится к запуску"
      },
      event_url: "bitsa"
    },
    {
      event_name: "Битца",
      event_date: "04.06.2022",
      volunteer_role_name: "Организатор",
      event_status: {
        id: 1,
        name: "готовится к запуску"
      },
      event_url: "bitsa"
    },
    {
      event_name: "Битца",
      event_date: "04.06.2022",
      volunteer_role_name: "Организатор",
      event_status: {
        id: 1,
        name: "готовится к запуску"
      },
      event_url: "bitsa"
    },
    {
      event_name: "Битца",
      event_date: "04.06.2022",
      volunteer_role_name: "Организатор",
      event_status: {
        id: 1,
        name: "готовится к запуску"
      },
      event_url: "bitsa"
    },
    {
      event_name: "Битца",
      event_date: "04.06.2022",
      volunteer_role_name: "Организатор",
      event_status: {
        id: 1,
        name: "готовится к запуску"
      },
      event_url: "bitsa"
    },
    {
      event_name: "Битца",
      event_date: "04.06.2022",
      volunteer_role_name: "Организатор",
      event_status: {
        id: 1,
        name: "готовится к запуску"
      },
      event_url: "bitsa"
    },
    {
      event_name: "Битца",
      event_date: "04.06.2022",
      volunteer_role_name: "Организатор",
      event_status: {
        id: 1,
        name: "готовится к запуску"
      },
      event_url: "bitsa"
    },
    {
      event_name: "Битца",
      event_date: "04.06.2022",
      volunteer_role_name: "Организатор",
      event_status: {
        id: 1,
        name: "готовится к запуску"
      },
      event_url: "bitsa"
    },
    {
      event_name: "Битца",
      event_date: "04.06.2022",
      volunteer_role_name: "Организатор",
      event_status: {
        id: 1,
        name: "готовится к запуску"
      },
      event_url: "bitsa"
    },
    {
      event_name: "Битца",
      event_date: "04.06.2022",
      volunteer_role_name: "Организатор",
      event_status: {
        id: 1,
        name: "готовится к запуску"
      },
      event_url: "bitsa"
    },
  ] */
  // *************************************************
  return (
    <div className={styles.chartblock}>
      <h2 className={`${styles.chartcaption} ${styles.chartcaptionvolunt}`}>Статистика волонтёрств</h2>
        <DoughnutChartMobile
          chartData={volunteerings}
          chartLabels={uniqueVolunteerRoles}
        />
      <p className={styles.statslink}>Вернуться к <Link to={AppRoute.Stats}>статистике</Link></p>
    </div>
  );
}

export default ChartVolunteeringScreen;
