import { Line } from 'react-chartjs-2'
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import { LineElement, PointElement, LinearScale, Title } from 'chart.js';
import { LineChartDataType } from '../../store/statistics/types';
import { MainColors, SecondaryColors } from '../../const';

Chart.register(LineElement, PointElement, LinearScale, Title);

type LineChartProps = {
  chartData: LineChartDataType,
  maxPace: string | undefined,
  minPace: string | undefined,
  xAxeTicks: string[] | undefined,
}

function LineChart(props: LineChartProps) {
  const { chartData, maxPace, minPace, xAxeTicks } = props;

  const lineChartData = {
    datasets: [
      {
        label: 'Темп',
        data: chartData,
        backgroundColor: SecondaryColors.SecondaryBlue,
        borderColor: MainColors.LightGreen,
        tension: 0.3,
      },
    ]
  }

  const lineChartOptions = {
    layout: {
      padding: {
        right: 25
      }
    },
    plugins: {
      legend: {
        display: false
     },
    },
    scales: {
      x: {
        display: true,
        reverse: true,
        title: {
          display: true,
        },
        grid: {
          display: true
        },
        ticks: {
          callback: function(index: any) {
            const ticks = xAxeTicks &&[...xAxeTicks];
            let xAxeTickValue;
            const ticksNums = ticks?.length;

            if (ticksNums) {
              let count: number;
              switch (ticksNums) {
                case Number(ticksNums > 30 && ticksNums <= 50):
                  count = 2;
                  break;
                case Number(ticksNums > 50 && ticksNums <= 70):
                  count = 4;
                  break;
                case Number(ticksNums > 70 && ticksNums <= 150):
                  count = 8;
                  break;
                default:
                  count = 1;
                  break;
              }

              if (index % count === 0) {
                xAxeTickValue = `${ticks[index].slice(0, 6)}${ticks[index].slice(8)}`;
              }
            }

            return xAxeTickValue;
          }
        }
      },
      y: {
        type: 'time' as const,
        time: {
          parser: 'mm:ss',
          unit: 'minute' as const,
          displayFormats: {
            minute: 'mm:ss'
          },
          tooltipFormat: 'mm:ss'
        },
        min: minPace,
        max: maxPace,
        // reverse: true,
        title: {
          display: true,
          text: 'мин/км'
        },
        reverse: true,
        grid: {
          display: true
        },
      }
    }
  }

  return (
    <Line
      data={lineChartData}
      options={lineChartOptions}
    />
  )
}

export default LineChart;

// <CoreChartOptions<"line"> & 
// ElementChartOptions<"line"> & 
// PluginChartOptions<"line"> & 
// DatasetChartOptions<"line"> & 
// ScaleChartOptions<...> & LineControllerChartOptions>
