import { createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import { APIRoute, ErrorCodes, WarningMessage } from "../../const";
import { api } from "../root-reducer";
import { SportmasterData } from "./types";
import { BonusProgramStatusType } from "../athlete/types";
import { setJoinSMStatus, setJoinAgreeStatus } from "./slice";

export const joinSportmasterProgramAction = createAsyncThunk(
  "data/join-sportmaster",
  async ({ phone, partner_name, personal_data_processing }: SportmasterData, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.post(APIRoute.JoinBonusProgram, {
        phone, 
        partner_name, 
        personal_data_processing
      });
      if (response.status === 200) {
        notification.success({ message: WarningMessage.JoinSportmasterProgramSuccess });
        dispatch(setJoinSMStatus(true));
        dispatch(setJoinAgreeStatus(false));
      }
    } catch (error: any) {
      switch (error.response.data.errorCode) {
        case ErrorCodes.DuplicatePhoneNumber:
          notification.warn({ message: WarningMessage.DuplicatePhoneNumberError });
          break;
        default:
          notification.warn({ message: WarningMessage.NetworkError });
      }

      return rejectWithValue(error.message);
    }
  }
);

export const updateSportmasterProgramAction = createAsyncThunk(
  "data/update-sportmaster",
  async ({ is_active }: BonusProgramStatusType, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.post(APIRoute.UpdateSMBonusProgram, {
        is_active
      });
      if (response.status === 200) {
        notification.success({ message: WarningMessage.UpdateSportmasterProgramSuccess });
        dispatch(setJoinSMStatus(false));
      }
    } catch (error: any) {
      switch (error.response.data.errorCode) {
        case ErrorCodes.DuplicatePhoneNumber:
          notification.warn({ message: WarningMessage.DuplicatePhoneNumberError });
          break;
        default:
          notification.warn({ message: WarningMessage.NetworkError });
      }

      return rejectWithValue(error.message);
    }
  }
);
