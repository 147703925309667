import { FormEvent, ChangeEvent, useState, useRef, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import styles from './index.module.scss';
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { setPasswordAction } from '../../store/password/thunk';
import { useAppDispatch } from "../../customHooks/useAppDispatch";
import { useAppSelector } from "../../customHooks/useAppSelector";
import { getPasswordStatus } from '../../store/password/selectors';
import { setPasswordChangeStatus } from '../../store/password/slice';
import { notification } from "antd";
import { AppRoute, WarningMessage, passwordRegEx } from '../../const';
import PasswordChecklist from "react-password-checklist";

function SetPasswordScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const isPasswordChanged = useAppSelector(getPasswordStatus);

  const passwordRef = useRef<HTMLInputElement | null>(null);
  const passwordRepeatRef = useRef<HTMLInputElement | null>(null);
  const buttonSetPasswordRef = useRef<HTMLButtonElement | null>(null);
  const linkToHomePageRef = useRef<HTMLAnchorElement | null>(null);

  const [ passwordNew, setPasswordNew ] = useState("");
  const [ passwordRepeat, setPasswordRepeat ] = useState("");
  const [ passwordNewType, setPasswordNewType ] = useState("password");
  const [ passwordRepeatType, setPasswordRepeatType ] = useState("password");
  const { hash } = useParams();

  useEffect(() => passwordRef.current?.focus(), []);

  const getFocus = () => {
    const focusableElements = [
      passwordRef.current,
      passwordRepeatRef.current,
      buttonSetPasswordRef.current,
      linkToHomePageRef.current
    ];
    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement = focusableElements[focusableElements.length - 1];

    document.addEventListener('keydown', (evt: KeyboardEvent) => {
      const isTabPressed = evt.key === 'Tab';

      if (!isTabPressed) {
        return;
      }

      if (evt.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement?.focus();
          evt.preventDefault();
        }
      } else {
        if (document.activeElement === lastFocusableElement) {
          firstFocusableElement?.focus();
          evt.preventDefault();
        }
      }
    });
  };

  useEffect(() => {
    getFocus();
  });

  const handlePasswordNewChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setPasswordNew(evt.target.value);
  };

  const handlePasswordRepeatChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    setPasswordRepeat(evt.target.value);
  };

  const handleSubmit = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    if (!passwordNew.match(passwordRegEx)) {
      notification.warn({ message: 'Проверьте правильность нового пароля' });
    }

    if (passwordNew && passwordRepeat) {
      if (passwordNew === passwordRepeat &&
        hash) {
      dispatch(setPasswordAction({
        password: passwordNew,
        hash
      }))
    } else {
      notification.warn({ message: WarningMessage.PasswordsNotIdentical });
    }

    } else {
      notification.warn({message: 'Поля паролей не могут быть пустыми'})
    }
    
  };

  const togglePasswordNewType = () => {
    if (passwordNewType === "text") {
      setPasswordNewType("password");
    } else {
      setPasswordNewType("text");
    }
  };

  const togglePasswordRepeatType = () => {
    if (passwordRepeatType === "text") {
      setPasswordRepeatType("password");
    } else {
      setPasswordRepeatType("text");
    }
  };

  const handleLinkClick = () => {
    dispatch(setPasswordChangeStatus(false));
  };

  return (
    <section className={styles.page}>
      {!isPasswordChanged && <form
        className="password-reminder"
        onSubmit={handleSubmit}
      >
        <div className={`${styles.wrapper} ${styles.newpassword}`}>
          <label className={styles.label} htmlFor="new-password">
            Введите новый пароль:
          </label>
          <input
            className={styles.input}
            value={passwordNew}
            type={passwordNewType}
            name="password-new"
            id="new-password"
            placeholder="Новый пароль"
            onChange={handlePasswordNewChange}
            ref={passwordRef}
          />
          <span className={styles.eye} onClick={togglePasswordNewType}>
            {passwordNewType === "password" ? (
              <span>
                <EyeOutlined />
              </span>
            ) : (
              <EyeInvisibleOutlined />
            )}
          </span>
        </div>
        <PasswordChecklist
          rules={["number", "lowercase", "capital", "minLength"]}
          minLength={8}
          value={passwordNew}
          messages={{
            number: "Пароль содержит цифру",
            lowercase: "Пароль содержит строчную букву",
            capital: "Пароль содержит заглавную букву",
            minLength: "Пароль длиннее 8 символов",
          }}
        />
        <div className={`${styles.wrapper} ${styles.repeatpassword}`}>
          <label className={styles.label} htmlFor="repeat-password">
              Введите пароль еще раз:
            </label>
            <input
              className={styles.input}
              value={passwordRepeat}
              type={passwordRepeatType}
              name="password-repeat"
              id="repeat-password"
              placeholder="Повторите пароль"
              onChange={handlePasswordRepeatChange}
              ref={passwordRepeatRef}
            />
            <span className={styles.eye} onClick={togglePasswordRepeatType}>
              {passwordRepeatType === "password" ? (
                <span>
                  <EyeOutlined />
                </span>
              ) : (
                <EyeInvisibleOutlined />
              )}
            </span>
        </div>
        <button
          className={styles.button}
          ref={buttonSetPasswordRef}
        >
          Изменить пароль
        </button>
      </form>}
      {isPasswordChanged && <h2>Пароль успешно изменен</h2>}
      <div>
        <Link
          className={styles.link}
          to={AppRoute.Login}
          onClick={handleLinkClick}
          ref={linkToHomePageRef}
        >
          Вернуться на главную
        </Link>
      </div>
    </section>
  )
}

export default SetPasswordScreen;

// module.scss two classes
// className={`${styles.description} ${styles.yellow}`}