import { ExternalLink } from "../../../const";

interface IProps {
  isLink: boolean;
}

const Logo: React.FC<IProps> = ({ isLink }) => {
  return (
    <a href={`${ExternalLink.VerstSiteUrl}`} target="_blank" rel="noreferrer">
      <img
        className="logo__img"
        src="img/5_verst_logo.png"
        alt="5 verst logo"
        width="100"
        height="29"
      />
    </a>
  );
};

export default Logo;
