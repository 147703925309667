/* eslint-disable @typescript-eslint/no-unused-vars */
import { Link } from 'react-router-dom';
import { 
  AppRoute, 
  ExternalLink
} from '../../const';
import styles from './index.module.scss';
import {
  getAthleteFirstName,
  getAthleteLastName,
  getAthleteMiddleName,
  getAthleteId,
  getRunCount,
  getVolunteeringSaturdays
} from '../../store/athlete/selectors';
import { useAppSelector } from '../../customHooks/useAppSelector';
import { getPersonalBestData } from '../../store/statistics/selectors';
import ClubRunIcon from '../ClubRunIcon/ClubRunIcon';
import ClubVolunteeringIcon from '../ClubVolunteeringIcon/ClubVolunteeringIcon';

function MainContent(): JSX.Element {
  const athleteFirstName = useAppSelector(getAthleteFirstName);
  const athleteLastName = useAppSelector(getAthleteLastName);
  const athleteMiddleName = useAppSelector(getAthleteMiddleName);
  const athleteID = useAppSelector(getAthleteId);

  const personalBestData = useAppSelector(getPersonalBestData);
  const clubMembershipData = personalBestData && personalBestData.club_membership;
  const clubRun = (personalBestData && clubMembershipData) && clubMembershipData.run;
  const clubVolunteering = (personalBestData && clubMembershipData) && clubMembershipData.volunteer;

  return (
    <section className={styles.content}>
      <h3
        className={styles.heading}
      >
        Участник 5 вёрст: {athleteFirstName} {athleteMiddleName} {athleteLastName?.toUpperCase()} (<a href={`${ExternalLink.UserStatsUrl}${athleteID}`} target="_blank" rel="noreferrer">A{athleteID}</a>)
      </h3>
      <div className={styles.blockicons}>
        <ClubRunIcon clubRun={clubRun} />
        <ClubVolunteeringIcon clubVolunteering={clubVolunteering} />
      </div>
      <ul className={styles.list}>
        <li className={styles.item}>
          <Link to={AppRoute.QRcode} className={styles.link}>
            Ваш QR-код
          </Link>
          <p className={styles.text}>Показать QR-код</p>
        </li>
        <li className={styles.item}>
          <Link to={AppRoute.Personal} className={styles.link}>
            Изменить данные
          </Link>
          <p className={styles.text}>Изменить личные данные</p>
        </li>
        <li className={styles.item}>
          <Link to={AppRoute.Stats} className={styles.link}>
            Статистика
          </Link>
          <p className={styles.text}>Посмотреть свою статистику участия в 5 вёрст</p>
        </li>
        <li className={styles.item}>
          <Link to={AppRoute.UpdatePassword} className={styles.link}>
            Изменить пароль
          </Link>
          <p className={styles.text}>Изменить свой действующий пароль</p>
        </li>
        <li className={styles.item}>
          <Link to={AppRoute.RzdBonusId} className={styles.link}>
            РЖД Бонус
          </Link>
          <p className={styles.text}>Ввести свой «РЖД Бонус» ID</p>
        </li>
        <li className={styles.item}>
          <Link to={AppRoute.SportmasterBonus} className={styles.link}>
            Спортмастер
          </Link>
          <p className={styles.text}>Участие в клубной программе Спортмастер</p>
        </li>
        {/* <li className={styles.item}>
          <Link to={AppRoute.Email} className={styles.link}>
            Настройки почты
          </Link>
          <p className={styles.text}>Почтовые данные, рассылка писем</p>
        </li> */}
        <li className={styles.item}>
          <Link to={AppRoute.Clubs} className={`${styles.link} ${styles.linkclubs}`}>
            Клубы 5 вёрст
          </Link>
          <p className={styles.text}>Узнать свой прогресс в клубах 5 вёрст</p>
        </li>
        <li className={styles.item}>
          <a className={styles.link} href={`${ExternalLink.VerstSiteUrl}`} target="_blank" rel="noreferrer">
            На сайт 5 вёрст
          </a>
          {/* <p className={styles.text}>Вернуться на сайт 5 вёрст</p> */}
        </li>
      </ul>
    </section>
  );
}

export default MainContent;