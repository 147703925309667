import { createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import { APIRoute, ErrorCodes, WarningMessage } from "../../const";
import { api } from "../root-reducer";
import { AthleteById, AthleteUpdateData } from "./types";

export const loadAthleteAction = createAsyncThunk(
  "data/athlete-data",
  async ({ id }: AthleteById, { rejectWithValue }) => {
    try {
      const response = await api.post(APIRoute.Athlete, {
        id,
      });
      return response.data.result;
    } catch (error: any) {
      if (error.code === ErrorCodes.NetworkError) {
        notification.warn({ message: WarningMessage.NetworkError });
      }
      return rejectWithValue(error.message);
    }
  }
);

export const loadEventsAction = createAsyncThunk(
  "data/events", async () => {
  try {
    const response = await api.post(APIRoute.Events);
    return response.data.result.events;
  } catch (error: any) {
    if (error.code === ErrorCodes.NetworkError) {
      notification.warn({ message: WarningMessage.NetworkError });
    }
  }
});

export const loadORDocAction = createAsyncThunk(
  "data/qrcode", async () => {
  try {
    const response = await api.post(APIRoute.PrintQRCode,
      {},
      {
        responseType: 'arraybuffer'
      }
    );
    const file = new Blob(
      [response.data], 
      {type: 'application/pdf'});
    const fileURL = window.URL.createObjectURL(file);
    window.open(fileURL);
  } catch (error: any) {
    if (error.code === ErrorCodes.NetworkError) {
      notification.warn({ message: WarningMessage.NetworkError });
    }
  }
});

export const updateAthleteAction = createAsyncThunk(
  "data/athlete-update",
  async (
    {
      first_name,
      middle_name,
      last_name,
      ice_name,
      ice_contact,
      home_event_id,
    }: AthleteUpdateData,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post(APIRoute.UpdateAthlete, {
        first_name,
        middle_name,
        last_name,
        ice_name,
        ice_contact,
        home_event_id,
      });
      if (response.status === 200) {
        notification.success({ message: WarningMessage.SaveSuccess });
      }
    } catch (error: any) {
      if (error.code === ErrorCodes.NetworkError) {
        notification.warn({ message: WarningMessage.NetworkError });
      }

      return rejectWithValue(error.message);
    }
  }
);

export const requestPasswordAction = createAsyncThunk(
  "data/password-request",
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await api.post(APIRoute.RequestPasswordChange, {
        email,
      });
      if (response.status === 200) {
        notification.success({
          message: WarningMessage.PasswordRequestSuccess,
        });
      }
    } catch (error: any) {
      switch (error.response.data.errorCode) {
        case ErrorCodes.BadIDRequest:
          notification.warn({ message: WarningMessage.BadIDRequest });
          break;
        case ErrorCodes.AthleteNotFound:
          notification.warn({ message: WarningMessage.AthleteNotFound });
          break;
        case ErrorCodes.InternalSystemError:
          notification.warn({ message: WarningMessage.InternalSystemError });
          break;
        default:
          notification.warn({ message: WarningMessage.NetworkError });
      }

      return rejectWithValue(error.message);
    }
  }
);
