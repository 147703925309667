import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { AppRoute, AuthorizationStatus } from "../../const";
import SignInScreen from "../../pages/SignInScreen/SignInScreen";
import MainScreen from "../../pages/MainScreen/MainScreen";
import PersonalScreen from "../../pages/PersonalScreen/PersonalScreen";
import RzdBonusIdScreen from "../../pages/RzdBonusIdScreen/RzdBonusIdScreen";
import SportmasterBonusScreen from "../../pages/SportmasterBonusScreen/SportmasterBonusScreen";
import QRcodeScreen from "../../pages/QRcodeScreen/QRcodeScreen";
import StatisticsScreen from "../../pages/StatisticsScreen/StatisticsScreen";
import ClubsScreen from "../../pages/ClubsScreen/ClubsScreen";
import SetPasswordScreen from "../../pages/SetPasswordScreen/SetPasswordScreen";
import NotFoundScreen from "../../pages/NotFoundScreen/NotFoundScreen";
import EmailSettingsScreen from "../../pages/EmailSettingsScreen/EmailSettingsScreen";
import ChartPaceScreen from "../../pages/ChartPaceScreen/ChartPaceScreen";
import ChartVolunteeringScreen from "../../pages/ChartVolunteeringScreen/ChartVolunteeringScreen";
import { useAppSelector } from "../../customHooks/useAppSelector";
import { useAppDispatch } from "../../customHooks/useAppDispatch";
import { getAuthorizationStatus } from "../../store/user/selectors";
import { getAthleteId } from "../../store/athlete/selectors";
import { useEffect } from "react";
import { dropToken, getToken } from "../../services/token";
import { setTokenFromStorage, userLogout } from "../../store/user/slice";
import decode from "jwt-decode";
import "./app.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "antd/dist/antd.min.css";
import { loadAthleteAction, loadEventsAction } from "../../store/athlete/thunk";
// import { getRzdBonusIdAction } from "../../store/rzdbonusid/thunk"; 
import PasswordForgot from "../../pages/PasswordForgot/PasswordForgot";
import UpdatePasswordScreen from "../../pages/UpdatePasswordScreen/UpdatePasswordScreen";

function App() {
  const authorizationStatus = useAppSelector(getAuthorizationStatus);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const athleteId = useAppSelector(getAthleteId);

  useEffect(() => {
    if (authorizationStatus === AuthorizationStatus.Auth) {
      dispatch(
        loadAthleteAction({
          id: athleteId,
        })
      );
      dispatch(loadEventsAction());
      /* dispatch(
        getRzdBonusIdAction({
          id: athleteId,
        })
      ); */
    }
  }, [authorizationStatus, dispatch, athleteId]);

  useEffect(() => {
    if (
      authorizationStatus !== AuthorizationStatus.Auth &&
      location.pathname !== AppRoute.RemindPassword &&
      /* location.pathname !== AppRoute.SetPassword && */
      !location.pathname.match(/^\/p\/.+$/)
    ) {
      const token = getToken();
      if (token) {
        try {
          const decodedToken: any = decode(token);
          // console.log(decodedToken?.exp);
          if (decodedToken.exp < Date.now() / 1000) {
            // console.log("Токен просрочен");
            dispatch(userLogout());
            navigate(AppRoute.Login);
          } else {
            dispatch(setTokenFromStorage(token));
            // console.log("Не авторизован, положил токен");
          }
        } catch (err) {
          // console.log("Дропаю токен, он поврежден");
          dropToken();
        }
      } else {
        navigate(AppRoute.Login);
      }
    }
  }, [authorizationStatus, dispatch, location.pathname, navigate]);

  return (
    <div className="wrap">
      <section className="header-block">
        <Header />
      </section>
      <main className="main-block">
        <Routes>
          <Route path={AppRoute.Home} element={<MainScreen />}></Route>
          <Route path={AppRoute.Login} element={<SignInScreen />} />
          <Route path={AppRoute.RemindPassword} element={<PasswordForgot />} />
          <Route path={AppRoute.UpdatePassword} element={<UpdatePasswordScreen />} />
          <Route path={AppRoute.SetPassword} element={<SetPasswordScreen />} />
          <Route path={AppRoute.Personal} element={<PersonalScreen />} />
          <Route path={AppRoute.Email} element={<EmailSettingsScreen />} />
          <Route path={AppRoute.RzdBonusId} element={<RzdBonusIdScreen />} />
          <Route path={AppRoute.SportmasterBonus} element={<SportmasterBonusScreen />} />

          <Route path={AppRoute.QRcode} element={<QRcodeScreen />} />
          <Route path={AppRoute.Stats} element={<StatisticsScreen />} />
          <Route path={AppRoute.Clubs} element={<ClubsScreen />} />
          <Route path={AppRoute.ChartPace} element={<ChartPaceScreen />} />
          <Route path={AppRoute.ChartVolunteering} element={<ChartVolunteeringScreen />} />
          <Route path="*" element={<NotFoundScreen />} />
        </Routes>
      </main>
      <section className="banner-block">
        <Footer />
      </section>
    </div>
  );
}

export default App;