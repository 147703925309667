import { Doughnut } from 'react-chartjs-2';
import { MainColors, SecondaryColors } from '../../const';
import Chart from 'chart.js/auto';

Chart.defaults.plugins.tooltip.boxPadding = 5;

type DoughnutChartProps = {
  chartData: number[] | undefined,
  chartLabels: string[] | undefined,
}

function DoughnutChartMobile(props: DoughnutChartProps) {
  const { chartData, chartLabels } = props;

  const doughnutChartData = {
    datasets: [
      {
        data: chartData,
        label: 'Кол-во волонтерств',
        backgroundColor: [
          MainColors.LightGreen,
          MainColors.Blue,
          MainColors.Red,
          MainColors.Yellow,
          SecondaryColors.Raspberry,
          SecondaryColors.DarkGreen,
          SecondaryColors.SecondaryBlue,
          SecondaryColors.DarkBlue,
        ],
      },
    ],
    labels: chartLabels,
  };

  const doughnutChartOptions = {
    plugins: {
      legend: {
        labels: {
          boxWidth: 30
        }
      }
    },
    aspectRatio: chartData?.length && 
    (chartData?.length > 7 ? 0.7 : 1),
  }

  Chart.overrides.doughnut.plugins.legend.position = 'bottom';

  return (
    <Doughnut
      data={doughnutChartData}
      options={doughnutChartOptions}
    />
  )
}

export default DoughnutChartMobile;
