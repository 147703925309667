import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SportmasterReducer } from "./types";

const initialState: SportmasterReducer = {
  isJoinSM: false,
  isJoinAgree: false,
  error: null,
  phone: '',
};

export const sportmasterBonusSlice = createSlice({
  name: "SportmasterBonusSlice",
  initialState,
  reducers: {
    setJoinSMStatus: (
      state: SportmasterReducer,
      action: PayloadAction<boolean>
    ) => {
      state.isJoinSM = action.payload;
    },
    setJoinAgreeStatus: (
      state: SportmasterReducer,
      action: PayloadAction<boolean>
    ) => {
      state.isJoinAgree = action.payload;
    },
    setAthletePhone: (
      state: SportmasterReducer,
      action: PayloadAction<string>
    ) => {
      state.phone = action.payload;
    },
  },
});

export const { setJoinSMStatus, setJoinAgreeStatus, setAthletePhone } = sportmasterBonusSlice.actions;
export default sportmasterBonusSlice.reducer;
