/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import styles from "./index.module.scss";
import { ChangeEvent, FormEvent, useEffect, useRef } from "react";
import { Checkbox, Spin } from "antd";
import type { CheckboxProps } from 'antd';
import { useAppSelector } from "../../customHooks/useAppSelector";
import { useAppDispatch } from "../../customHooks/useAppDispatch";
import { getRzdBonusIdAction, setRzdBonusIdAction } from "../../store/rzdbonus/thunk";
import { setRzdBonusId, setCheckboxAgree } from "../../store/rzdbonus/slice";
import { getAthleteId } from "../../store/athlete/selectors";
import { 
  getIsRzdBonusIdLoading, 
  getIsRzdBonusIdLoaded, 
  getRzdBonusId,
  getRzdBonusStatus,
  getRzdBonusIDRequestStatus,
  getRzdBonusAthleteId,
  getDisabledInputStatus,
  getCheckboxAgreeStatus,
} from "../../store/rzdbonus/selectors";
import { validateRzdBonusId } from "../../utils"; 
import { RzdBonusStatus } from "../../const";

function RzdBonusIdScreen(): JSX.Element {
  const dispatch = useAppDispatch();

  const isRzdBonusIdLoading = useAppSelector(getIsRzdBonusIdLoading);
  const isRzdBonusIdLoaded = useAppSelector(getIsRzdBonusIdLoaded);

  const athleteId = useAppSelector(getAthleteId) as number;
  const rzdBonusId = useAppSelector(getRzdBonusId);
  const rzdBonusAthleteId = useAppSelector(getRzdBonusAthleteId);
  const rzdBonusStatus = useAppSelector(getRzdBonusStatus);
  const rzdBonusIDRequestStatus = useAppSelector(getRzdBonusIDRequestStatus);
  const isDisabledInput = useAppSelector(getDisabledInputStatus);
  const isCheckboxAgree = useAppSelector(getCheckboxAgreeStatus);

  const rzdBonusIdInputRef = useRef<HTMLInputElement | null>(null);
  const buttonSubmitRef = useRef<HTMLButtonElement | null>(null);

  const onChangeCheckbox: CheckboxProps['onChange'] = (e) => {
    dispatch(setCheckboxAgree(true));
  };

  useEffect(() => {
    if (!isRzdBonusIdLoaded && !rzdBonusId) {
      dispatch(
        getRzdBonusIdAction({
          id: athleteId,
        })
      );
    }
  }, []);

  const onBlurInput = (evt: ChangeEvent<HTMLInputElement>): void => {
    if (evt.target === rzdBonusIdInputRef.current) {
      rzdBonusIdInputRef.current.setCustomValidity(validateRzdBonusId(rzdBonusIdInputRef.current.value));
      rzdBonusIdInputRef.current.reportValidity();
    }
  }

  const handleSubmit = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (rzdBonusId) {
      dispatch(
        setRzdBonusIdAction({
          rzd_bonus_id: parseInt(rzdBonusId, 10),
          is_agree: isCheckboxAgree,
        })
      );
    }
  };

  const rzdBonusIdRegex = /^[0-9]*$/;

  const handleInputRzdBonusId = (evt: ChangeEvent<HTMLInputElement>): void => {
    const currentRzdBonusId = evt.target.value;
    if (currentRzdBonusId.match(rzdBonusIdRegex)) {
      dispatch(setRzdBonusId(currentRzdBonusId));
    }
  };

  const showRzdBonusStatus = () => {
    switch (rzdBonusIDRequestStatus) {
      case RzdBonusStatus.Accepted:
        return  <div>
                  {rzdBonusStatus ? (
                    <>
                      <strong>Ваш статус: <span className={styles.active}>Активен</span></strong>
                      <p className={styles.plaintext}>Спасибо за ваше активное участие в стартах 5 вёрст!<br />Путешествуйте по стране вместе с «РЖД Бонус» и получайте 
                        <a href="https://5verst.ru/partners/rzd-bonus/" target="_blank" rel="noreferrer"> дополнительные бонусы</a>!
                      </p>
                    </>
                  ) : (
                    <>
                      <strong>Ваш статус: <span className={styles.notactive}>Не активен</span></strong>
                      <p className={styles.plaintext}>Чтобы активировать специальные условия по начислению «РЖД Бонус», принимайте участие в мероприятиях 5 вёрст не реже одного раза в 3 месяца.
                        <a href="https://5verst.ru/partners/rzd-bonus/" target="_blank" rel="noreferrer"> Подробнее...</a>
                      </p>
                    </>
                  )}
                  <p className={styles.plaintext}>Для изменения «РЖД Бонус» ID обратитесь в <a href="https://5verst.ru/support/" target="_blank" rel="noreferrer">поддержку</a>.</p>
                </div>;
      case RzdBonusStatus.Pending:
      case RzdBonusStatus.Processing:
        return  <div>
                  <strong>Ваш статус: <span className={styles.processing}>На проверке</span></strong>
                  <p className={styles.plaintext}>После проверки вашего номера «РЖД Бонус» вы сможете получать 
                    <a href="https://5verst.ru/partners/rzd-bonus/" target="_blank" rel="noreferrer"> дополнительные бонусы</a>, путешествуя по стране. 
                  </p>
                </div>;
      case RzdBonusStatus.Declined:
        return  <div>
                  <strong>Ваш статус: <span className={styles.notactive}>Не найден</span></strong>
                  <p className={styles.plaintext}>К сожалению, ваш номер «РЖД Бонус» не найден. Уточните номер и обратитесь в
                    <a href="https://5verst.ru/support/" target="_blank" rel="noreferrer"> поддержку</a>.
                  </p>
                </div>;
    }
  };

  return (
    <div className={styles.mainwrapper}>
      <section className={styles.page}>
        <h2>Специальные условия от программы «РЖД Бонус» участникам 5 вёрст.</h2> 
        <p className={styles.textone}>Участники 5 вёрст получают на 10% больше баллов в программе «РЖД Бонус» и 
        быстрее копят баллы на будущие путешествия по железной дороге.</p>
        <p className={styles.texttwo}>
          <a href="https://5verst.ru/partners/rzd-bonus/" 
            className={styles.programlink}
            target="_blank" 
            rel="noreferrer" 
            tabIndex={1}
          >Узнай подробности.
          </a>
        </p>
        <h3 className={styles.heading}>{rzdBonusId ? 'Ваш ' : 'Введите свой '} &laquo;РЖД Бонус&raquo; ID:</h3>
        <form 
          className="personal-data"
          onSubmit={handleSubmit}
        >
          <div className={styles.block}>
            <div className={styles.wrapper}>
              <input
                className={styles.input}
                value={rzdBonusId}
                placeholder="9002123456789"
                type="text"
                name="rzdbonusid"
                ref={rzdBonusIdInputRef}
                onChange={handleInputRzdBonusId}
                onBlur={onBlurInput}
                tabIndex={2}
                disabled={isDisabledInput}
              />
            </div>
          </div>
          {!rzdBonusAthleteId ? (
            <div>
              <p className={styles.warning}>Данное поле можно заполнить только один раз, указав ваш личный номер участника «РЖД Бонус». 
                Если вам необходимо изменить введенный номер «РЖД Бонус», просьба обратиться в <a href="https://5verst.ru/support/" target="_blank" rel="noreferrer">поддержку</a>.</p>
              <Checkbox 
                className={styles.checkbox}
                onChange={onChangeCheckbox} 
                checked={isCheckboxAgree}
                tabIndex={3}
              >
                Согласен на обработку моих персональных данных, а именно: номера участника программы «РЖД Бонус» в целях передачи. 
                Согласен на передачу моих персональных данных, а именно: фамилии, имени и отчества, номера участника программы «РЖД Бонус» и данных по участию в мероприятиях 5 вёрст, 
                в АО «Федеральная пассажирская компания» для начисления баллов в бонусной системе.
              </Checkbox>
              {isRzdBonusIdLoading ? (
                <div className={styles.spinner}>
                  <Spin size="large" />
                </div>
              ) : null}
              <button 
                className={styles.button}
                ref={buttonSubmitRef}
                disabled={!isCheckboxAgree || !rzdBonusId}
                tabIndex={4}
              >
                Отправить
              </button>
            </div>
          ) : showRzdBonusStatus()}
        </form>
      </section>
    </div>
  );
}

export default RzdBonusIdScreen;
