import styles from './index.module.scss';
import { ClubIconSize } from '../../const';

type TableCellTShirtProps = {
  colSpan: number;
  eventCount: number | undefined;
  iconCount: number;
  iconName: string;
  linkpostfix: string;
  tshirtPrefix: string;
  gender: string;
  altText: string;
}

function TableCellTShirt(props: TableCellTShirtProps): JSX.Element {
  const { eventCount, colSpan, iconName, iconCount, linkpostfix, tshirtPrefix, gender, altText } = props;

  return (
    <td className={styles.tcell} colSpan={colSpan}>{(eventCount && eventCount >= iconCount) ? 
        <a className={styles.link} href={`https://shop.5verst.ru/product/${tshirtPrefix}${iconCount}${linkpostfix}-${gender}/?utm_source=lk&utm_medium=clubs&utm_campaign=regular`} target="_blank" rel="noreferrer">
            <img src={`img/${iconName}.svg`} width={ClubIconSize.Width} height={ClubIconSize.Height} alt={`Иконка ${altText}`} />
        </a> 
        : <img className={styles.nonclickableicon} src={`img/${iconName}.svg`} width={ClubIconSize.Width} height={ClubIconSize.Height} alt={`Иконка ${altText}`} />}
    </td>
  );
}

export default TableCellTShirt;