import { NameSpace } from "../root-reducer";
import { State } from "../../types/state";
import { StatisticsType, StatsEventsType, VolunteeringsType, PersonalBestType } from "./types";

export const getAthleteStatistics = (state: State): StatisticsType | null =>
  state[NameSpace.stats].statistics;
export const getAthleteFullName = (state: State): string | undefined =>
  state[NameSpace.stats].statistics?.full_name;
export const getStatsEvents = (state: State): StatsEventsType | undefined =>
  state[NameSpace.stats].statistics?.stat.events;
export const getStatsVolunteerings = (state: State): VolunteeringsType | undefined =>
  state[NameSpace.stats].statistics?.stat.volunteering;
export const getFinishTimes = (state: State): string[] | undefined =>
  state[NameSpace.stats].statistics?.stat.events?.map((event) => event.finish_time);
export const getEventNames = (state: State): string[] | undefined =>
  state[NameSpace.stats].statistics?.stat.events?.map((event) => event.event_name);
export const getGender = (state: State): string | undefined =>
  state[NameSpace.stats].statistics?.age_group.slice(0,1);
export const getVolunteerRoles = (state: State): string[] | undefined =>
  state[NameSpace.stats].statistics?.stat.volunteering?.map((volunteeering) => volunteeering.volunteer_role_name);
export const getEventDates = (state: State): string[] | undefined =>
  state[NameSpace.stats].statistics?.stat.events?.map((event) => event.event_date);
export const getPersonalBestData = (state: State): PersonalBestType | undefined => 
  state[NameSpace.stats].statistics?.personal_best;
export const getIsLoading = (state: State) => state[NameSpace.stats].isLoading;
export const getIsLoaded = (state: State): boolean =>
  state[NameSpace.stats].isLoaded;
