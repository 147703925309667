/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from './index.module.scss';
import { useAppSelector } from '../../customHooks/useAppSelector';
import {
  getAthleteFullName,
  getGender,
  getPersonalBestData
} from '../../store/statistics/selectors';
import {
  getRunCount,
  getVolunteeringSaturdays
} from '../../store/athlete/selectors';
import { EventCounts, ClubRuns, ClubVolunteers, minClubMembershipIndex } from '../../const';
import ClubTableRow from '../../components/ClubTableRow/ClubTableRow';
import { nanoid } from 'nanoid';

function ClubsScreen(): JSX.Element {
  const athleteFullName = useAppSelector(getAthleteFullName);
  const athleteGender = useAppSelector(getGender);

  const personalBestData = useAppSelector(getPersonalBestData);
  const clubMembershipData = personalBestData && personalBestData.club_membership;
  const clubRun = ((personalBestData && clubMembershipData) && clubMembershipData.run) as string;
  const clubVolunteering = ((personalBestData && clubMembershipData) && clubMembershipData.volunteer) as string;

  const indexClubRun = ClubRuns.indexOf(clubRun);
  const indexClubVolunteering = ClubVolunteers.indexOf(clubVolunteering);
  
  const arrClubRun = ClubRuns.slice(0, indexClubRun + 2);
  const arrClubVolunteering = ClubVolunteers.slice(0, indexClubVolunteering + 2);

  const setAthleteGender = () => {
    let gender: string = '';
    if (athleteGender) {
      if (athleteGender === 'М') {
        gender = 'male';
      } else if (athleteGender === 'Ж') {
        gender = 'female';
      }
    }

    return gender;
  }

  const runCount = useAppSelector(getRunCount);
  const volunteeringCount = useAppSelector(getVolunteeringSaturdays);

  return (
    <div className={styles.mainwrapper}>
      <section className={styles.section}>
        <h1 className={styles.athletename}>{athleteFullName} - клубный прогресс участника.</h1>
        <p className={styles.textblock}>Регулярное участие для нас ценнее результатов.<br />Клубные футболки можно заказать на 
          <a href="https://shop.5verst.ru/" target="_blank" rel="noreferrer"> shop.5verst.ru </a> 
          по достижении 10, 25, 50, 100, 250 или 500 мероприятий 5 вёрст в качестве финишёра или волонтёра.
        </p>
        <p>Всего финишей: {runCount}</p>
        <table className={styles.starts}>
          <thead>
            <tr className={styles.trowheading}>
              <th className={styles.theading}>Клуб</th>
              <th className={styles.theading}>Подробнее</th>
              <th className={styles.theading} colSpan={2}>Заказать</th>
            </tr>
          </thead>
          <tbody>
            {!arrClubRun ? 
            <ClubTableRow
              key={nanoid()}
              eventCount={runCount}
              iconCount={EventCounts[minClubMembershipIndex]}
              clubRunVolunteering={ClubRuns[minClubMembershipIndex]}
              linkpostfix='participations' 
              gender={setAthleteGender()}
            /> :
            arrClubRun.map((el) => {
              const currIndex = arrClubRun.indexOf(el); 
              return (
                <ClubTableRow
                  key={nanoid()}
                  eventCount={runCount}
                  iconCount={EventCounts[currIndex]}
                  clubRunVolunteering={el}
                  linkpostfix='participations' 
                  gender={setAthleteGender()}
                />
              )
            })}
          </tbody>
        </table>
        <p>Всего волонтёрств: {volunteeringCount}</p>
        <table className={styles.volunteerings}>
          <thead>
            <tr className={styles.trowheading}>
              <th className={styles.theading}>Клуб</th>
              <th className={styles.theading}>Подробнее</th>
              <th className={styles.theading} colSpan={2}>Заказать</th>
            </tr>
          </thead>
          <tbody>
            {!arrClubVolunteering ? 
            <ClubTableRow
              key={nanoid()}
              eventCount={volunteeringCount}
              iconCount={EventCounts[minClubMembershipIndex]}
              clubRunVolunteering={ClubVolunteers[minClubMembershipIndex]}
              linkpostfix='participations' 
              gender={setAthleteGender()}
            /> :
            arrClubVolunteering.map((el) => {
              const currIndex = arrClubVolunteering.indexOf(el); 
              return (
                <ClubTableRow
                  key={nanoid()}
                  eventCount={volunteeringCount}
                  iconCount={EventCounts[currIndex]}
                  clubRunVolunteering={el}
                  linkpostfix='volunteers' 
                  gender={setAthleteGender()}
                />
              )
            })}
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default ClubsScreen;
