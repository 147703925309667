import { combineReducers } from "redux";
import userReducer, { userLogout } from "./user/slice";
import athleteReducer from "./athlete/slice";
import statisticsReducer from "./statistics/slice";
import passwordReducer from "./password/slice";
import RzdBonusIdReducer from "./rzdbonus/slice";
import SportmasterReducer from '../store/sportmasterbonus/slice';
import { createAPI } from "../services/api";
import { configureStore } from "@reduxjs/toolkit";
import { dropToken } from "../services/token";

// потом вынести в const
export enum NameSpace {
  user = "USER",
  athlete = "ATHLETE",
  stats = "STATISTICS",
  password = "PASSWORD",
  rzdbonus = "RZDBONUS",
  sportmasterbonus = "SPORTMASTERBONUS",
}

// export const api = createAPI(() =>
//   store.dispatch(requireAuthorization(AuthorizationStatus.NoAuth))
// );

// createAPI получает функцию которая выполняется при респонсе 401
export const api = createAPI(() => {
  dropToken();
  store.dispatch(userLogout());
  console.log("Токен просрочен");
});

export const rootReducer = combineReducers({
  [NameSpace.user]: userReducer,
  [NameSpace.athlete]: athleteReducer,
  [NameSpace.stats]: statisticsReducer,
  [NameSpace.password]: passwordReducer,
  [NameSpace.rzdbonus]: RzdBonusIdReducer,
  [NameSpace.sportmasterbonus]: SportmasterReducer,
});

export const store = configureStore({ reducer: rootReducer });

export type AppDispatch = typeof store.dispatch;

// Может поломаться, говорить что типизация по кругу, тогда надо будет типизировать
// взяв типы редюсеров
export type RootState = ReturnType<typeof rootReducer>;
