import { LineChartDataType } from "./store/statistics/types";

export const validateLogin = (login: string): string => {
  const loginRegex = /(?=.*[A])(?=.*[0-9])[A0-9]{5,}/;
  if (!loginRegex.test(login)) {
    return "Введите корректный логин";
  }
  return "";
};

export const validateName = (name: string): string => {
  const nameRegex = /^[a-zA-Zа-яА-ЯёЁ-]*$/;
  if (!nameRegex.test(name)) {
    return "Поле может содержать только буквы кириллицы или латиницы, или дефис";
  }
  return "";
};

/* export const validatePhone = (phone: string): string => {
  const phoneRegex = /^[0-9 +()-]*$/;
  if (!phoneRegex.test(phone)) {
    return "Поле может содержать только цифры и символы + - ( )";
  }
  return "";
}; */

export const validateEmail = (email: string): string => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!emailRegex.test(email)) {
    return 'Введите корректный email';
  }
  return '';
};

export const validateRzdBonusId = (rzdbonusid: string): string => {
  const inputLength = rzdbonusid.length;
  const firstFourChar = rzdbonusid.slice(0,4);
  if (inputLength !== 13 || firstFourChar !== '9002') {
    return '«РЖД Бонус ID» должен начинаться с 9002 и иметь 13 цифр';
  }
  return '';
};

export const validatePhoneLength = (phone: string): string => {
  const inputLength = phone.length;
  if (inputLength !== 16) {
    return 'Введите корректный номер телефона';
  }
  return '';
}

export const getPace = (finishTime: string | undefined) => {
  const arrValuesStr = finishTime && finishTime.split(':').reverse();
  let totalSeconds = 0;
  if (arrValuesStr) {
    for (let i = 0; i < arrValuesStr?.length; i++) {
      totalSeconds += Number(arrValuesStr[i]) * Math.pow(60, i);
    }
  }
  const minutes = Math.trunc(totalSeconds / 5 / 60);
  const seconds = (totalSeconds / 5 - minutes * 60) / 100;
  const finalSecs = ((seconds * 100) % 10 === 0) ? seconds.toFixed(2) : seconds;
  const secondsStr = finalSecs.toString().slice(2).slice(0,2);
  
  const pace = `${minutes}:${secondsStr}`;

  return pace; 
};

export const concatArrayElements = (arr1: string[] | undefined, arr2: string[] | undefined) => {
  let newArr: string[] = [];
  if (arr1 && arr2 && arr1.length === arr2.length) {
    for (let i = 0; i < arr1.length; i++) {
      newArr.push(`${arr1[i]}, ${arr2[i]}`);
    }
  }
  return newArr;
};

export const getMaxRoundElement = (arr: string[] | undefined) => {
  const arrNumbers = arr?.map((element) => Number(element.replace(':', '.')));
  const maxElement = arrNumbers?.reduce((max, value) => (value > max ? value : max), 0);
  let maxElementString: string | undefined;
  if (maxElement) {
    maxElementString = Math.ceil(maxElement).toFixed(2).toString();
  }

  return maxElementString?.replace('.', ':');
}

export const getMinRoundElement = (arr: string[] | undefined) => {
  const arrNumbers = arr?.map((element) => Number(element.replace(':', '.')));
  const minElement = arrNumbers?.reduce((min, value) => (value < min ? value : min));
  let minElementString: string | undefined;
  if (minElement) {
    minElementString = Math.floor(minElement).toString();
  }
  return minElementString?.replace('.', ':');;
}

export const getChartData = (paces: string[] | undefined, dates: string[] | undefined, names: string[] | undefined): LineChartDataType => {
  let chartData;
  if (paces && dates && names) {
    chartData = paces?.map((pace, index) => ({
      x: `${dates[index]}, ${names[index]}`,
      y: pace,
    }));
  }
  return chartData;
};

export const getUniqueItems = (arr: string[] | undefined): string[] | undefined => {
  const uniqueItems = arr?.reduce((unique: string[], role) => unique.indexOf(role) !== -1 ? unique : [...unique, role], []);
  return uniqueItems;
};

export const getArrayOfUniqueItemsQuantities = (arrAll: string[] | undefined, arrUnique: string[] | undefined): number[] | undefined=> {
  let resultArr: number[] = [];
  if (arrAll && arrUnique) {
    for (let i = 0; i < arrUnique.length; i++) {
    const numUnique = arrAll.filter(element => element === arrUnique[i]).length; 
    resultArr.push(numUnique);
    }
  }
  return resultArr;
};

export const getDataChunk = (data: LineChartDataType, numItem: number): LineChartDataType => {
  if (data) {
    return data.length > numItem ? data.slice(0, numItem) : data; 
  }
};  

export const validateNewPassword = (password: string): string => {
  const passwordRegex = /^[0-9a-zA-Z{8,}]*$/;
  const passwordLength = password.length;
  if (!passwordRegex.test(password) || passwordLength < 8) {
    return 'Пароль должен содержать хотя бы одну заглавную букву, одну строчную, одну цифру и быть не короче 8 символов';
  }
  return '';
};

/* export const setCountIcon = (itemCount: number | undefined) => {
  if (itemCount) {
    if (10 <= itemCount &&  itemCount < 25) {
      return 10;
    }
    if (25 <= itemCount &&  itemCount < 50) {
      return 25;
    }
    if (50 <= itemCount &&  itemCount < 100) {
      return 50;
    }
    if (100 <= itemCount &&  itemCount < 250) {
      return 100;
    }
    if (250 <= itemCount &&  itemCount < 500) {
      return 250;
    }
  }
}; */

/* export const saveBannerId = (id: string): void => {
  sessionStorage.setItem('bannerId', id);
};

export const getBannerId = (): string => {
  const id = sessionStorage.getItem('bannerId');
  return id ?? '';
}; */
